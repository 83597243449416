<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
      <!-- <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative" @submit.prevent="handleSearch">
          <input type="text" class="form-control shadow-none text-black rounded-0 border-0" placeholder="Search role"
            v-model="searchQuery" />
          <button type="submit" class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <select class="project-select form-select shadow-none fw-semibold rounded-1 mt-10 mt-sm-0 ms-sm-10" v-model="pageSize" @change="fetchRoles(searchQuery)">
          <option :value="5">5</option>
          <option :value="10">10</option>
          <option :value="25">25</option>
          <option :value="50">50</option>
        </select>
      </div> -->
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive" style="overflow: visible;">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
                  Type
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
                  Parameter
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
                  Guid
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
                  Created On
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0">
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="data in filteredData" :key="data.id">
              <td class="shadow-none title lh-1 fw-medium fs-md-15 fs-lg-16 ps-0">
                {{ getElisaEmbedTypeDisplayName(data.embedType) }}
              </td>
              
              <td
                  v-if="data.embedType === ElisaEmbedTypeEnum.OrderDataOverview && data.parameters"
                  class="shadow-none title lh-1 fw-medium fs-md-15 fs-lg-16 ps-0"
              >
                <div class="embed-details">
                    <div class="embed-item">
                        <span class="embed-label">Table Name: </span>
                        <span class="embed-value">{{ parsedParameters(data.parameters).TableName || "N/A" }}</span>
                    </div>
                    <div class="embed-item">
                        <span class="embed-label">Period: </span>
                        <span class="embed-value">{{ parsedParameters(data.parameters).Period || "N/A" }}</span>
                    </div>
                    <div class="embed-item">
                        <span class="embed-label">Start Date: </span>
                        <span class="embed-value">{{ new Date(parsedParameters(data.parameters).StartDate).toLocaleString() || "N/A" }}</span>
                    </div>
                    <div class="embed-item">
                        <span class="embed-label">End Date: </span>
                        <span class="embed-value">{{ new Date(parsedParameters(data.parameters).EndDate).toLocaleString() || "N/A" }}</span>
                    </div>
                </div>
              </td>

              <td v-else class="shadow-none title lh-1 fw-medium fs-md-15 fs-lg-16 ps-0">
                Not Supported embed type
              </td>

              <td class="shadow-none title lh-1 fw-medium fs-md-15 fs-lg-16 ps-0">
                {{ data.id }}
              </td>

              <td class="shadow-none title lh-1 fw-medium fs-md-15 fs-lg-16 ps-0">
                {{ new Date(data.createdDate).toLocaleString() }}
                
              </td>
              
              <td class="shadow-none lh-1 fw-medium text-end pe-0">
                  <button class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="flaticon-dots"></i>
                  </button>
                  <div class="dropdown">
                      <ul class="dropdown-menu">
                          <li>
                              <a
                                  class="dropdown-item d-flex align-items-center"
                                  href="#" @click.prevent="deleteEmbed(data.id)"
                                  >
                                  <i class="flaticon-delete lh-1 me-8 position-relative top-1"
                                  > </i>
                                  Delete
                              </a>
                          </li>
                      </ul>
                  </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination-area d-md-flex mt-15 mt-md-25 mb-0 justify-content-between align-items-center">
        <p class="mb-0 text-paragraph">
          Showing <span class="fw-bold">{{ filteredData.length }}</span> out of
          <span class="fw-bold">{{ totalData }}</span> results
        </p>
        <nav class="mt-15 mt-md-0">
          <ul class="pagination mb-0">
            <li class="page-item" :class="{ disabled: pageIndex === 1 }">
              <a class="page-link" href="#" aria-label="Previous" @click.prevent="changePage(pageIndex - 1)">
                <i class="flaticon-chevron-1"></i>
              </a>
            </li>
            <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: page === pageIndex }">
              <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
            </li>
            <li class="page-item" :class="{ disabled: pageIndex === totalPages }">
              <a class="page-link" href="#" aria-label="Next" @click.prevent="changePage(pageIndex + 1)">
                <i class="flaticon-chevron"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>

</template>

<script>
import { ElisaEmbedTypeEnum, getElisaEmbedTypeDisplayName } from "@/core/constant/ElisaEmbedTypeEnum";
import { GetAllEmbedPages, DeleteEmbed } from '@/core/services/entities/elisaEmbed.service'
import { useToast } from 'vue-toastification'
import { useRoute } from "vue-router";
import Swal from 'sweetalert2';
import { mapGetters} from 'vuex'
import breadcrumbMixin from '@/mixins/BreadcrumbMixin'

export default {
  name: "EmbedList",data() {
    return {
      searchQuery: "",          
      filteredData: [],         
      pageSize: 10,             
      pageIndex: 1,             
      totalData: 0,             
      totalPages: 1,            
      ElisaEmbedTypeEnum,
      groupKey: null, 
      embedType: null       
    };
  },
  mixins: [breadcrumbMixin],
  methods: {
    getElisaEmbedTypeDisplayName,
    parsedParameters(parameters) {
      try {
      return typeof parameters === "string" ? JSON.parse(parameters) : parameters;
      } catch (error) {
      console.error("Error parsing parameters:", error);
      return {};
      }
    },
    async fetchDataTable(filter = "") {
      const params = {
        page: this.pageIndex,
        pageSize: this.pageSize,
        embedType: this.embedType,      // Optional filter
        groupKey: this.groupKey, // Optional filter
      };

      let data = await GetAllEmbedPages(params);
      if(data)
      {
        this.filteredData = data.embedList;
        this.totalData = data.count;
        this.totalPages = Math.ceil(this.totalData / this.pageSize);
      }
    },
    handleSearch(event) {
      event.preventDefault();
      const filter = this.searchQuery.trim();
      this.pageIndex = 1; 
      this.fetchDataTable(filter);
    },
    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.pageIndex = page;
        this.fetchDataTable(this.searchQuery);
      }
    },
    async deleteEmbed(embedId) {
      const toast = useToast();

      // Show confirmation dialog using SweetAlert2
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {  
        if (result.isConfirmed) {
          try {
            const payload = { id: embedId };
            let response = await DeleteEmbed(payload);  
            if (response.status === 200) {
              toast.success('Embed Pages deleted successfully!', {
                timeout: 2000,
              });
              this.fetchDataTable();
            }
          } catch (ex) {
            const errorMessage = ex.response?.data || ex.message || "An unknown error occurred";
            toast.error(`Failed to delete Embed Pages: ${errorMessage}`, {
              timeout: 2000,
            });
          }
        } else {
          toast.info('Embed Pages deletion canceled.', { timeout: 2000 });
        }
      });

    },
    openUpdateLightbox (role){
      this.selectedRole = role;
      this.showUpdateLightbox = true;
    },
    closeUpdateLightbox(){
      this.showUpdateLightbox = false;
    },
    handleDataStreamUpdated(){
      this.closeUpdateLightbox();
      // fetchDataStreams(searchQuery.value);
    }
  },
  computed:{...mapGetters(['allowAdd'])},
  mounted() { 
    const route = useRoute();
    // Retrieve groupKey and enumType from the route query
    const groupKey = route.query.groupKey? route.query.groupKey : undefined;
    const embedType = route.query.embedType ? parseInt(route.query.embedType) : undefined;
    
    // Assign them to the component's data
    this.groupKey = groupKey ?? null;
    this.embedType = embedType ?? null;

    this.fetchDataTable();
  }
};
</script>


