<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing lead-info-box">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <div class="profile-info d-sm-flex align-items-center">
        <img
          src="https://shop.raceya.fit/wp-content/uploads/2020/11/logo-placeholder.jpg"
          class="rounded"
          alt="user"
        />
        <div class="title mt-12 mt-sm-0">
          <h5 class="text-black fw-medium mb-8">{{ clientName }}</h5>
          <span class="d-block fs-md-15 fs-lg-16 text-dark-emphasis mb-8">
            {{ enquiryID }}
          </span>
          <span class="d-block fw-bold text-primary"> {{ salesman }} </span>
        </div>
      </div>
      <div class="border-top mt-15 mb-15 mt-sm-20 mb-sm-20 mt-md-25 mb-md-25 mt-lg-30 mb-lg-30"></div>
      <div class="d-flex align-items-center justify-content-between">
        <h5 class="fw-medium text-black-emphasis mb-0">Project Information</h5>
        <router-link
          to="/lead-details"
          class="d-inline-block text-decoration-none lh-1 text-muted fw-medium"
        >
          <i class="ph-duotone ph-pencil text-black position-relative top-1 fs-16 me-1"></i>
          Edit
        </router-link>
      </div>
      <ul class="info mt-25 ps-0 mb-0 list-unstyled">
        <li class="position-relative">
          <div class="icon text-primary rounded-circle text-center">
            <i class="flaticon-web"></i>
          </div>
          <span class="d-block text-black mb-5 fw-semibold">Project Description</span>
          <span class="d-inline-block fs-md-15 fs-lg-16 text-muted">
            {{ projectDescription }}
          </span>
        </li>
        <li class="position-relative">
          <div class="icon text-success rounded-circle text-center">
            <i class="flaticon-mail-inbox-app"></i>
          </div>
          <span class="d-block text-black mb-5 fw-semibold">Enquiry ID</span>
          <span class="d-inline-block fs-md-15 fs-lg-16 text-primary">
            {{ enquiryID }}
          </span>
        </li>
        <li class="position-relative">
          <div class="icon text-info rounded-circle text-center">
            <i class="flaticon-telephone-call"></i>
          </div>
          <span class="d-block text-black mb-5 fw-semibold">Status</span>
          <span class="d-inline-block fs-md-15 fs-lg-16 text-muted">
            {{ status }}
          </span>
        </li>
        <li class="position-relative">
          <div class="icon text-primary rounded-circle text-center">
            <i class="flaticon-web"></i>
          </div>
          <span class="d-block text-black mb-5 fw-semibold">Salesperson</span>
          <span class="d-inline-block fs-md-15 fs-lg-16 text-muted">
            {{ salesman }}
          </span>
        </li>
        <li class="position-relative">
          <div class="icon text-primary rounded-circle text-center">
            <i class="flaticon-web"></i>
          </div>
          <span class="d-block text-black mb-5 fw-semibold">Business Area</span>
          <span class="d-inline-block fs-md-15 fs-lg-16 text-muted">
            {{ businessArea }}
          </span>
        </li>
        <li class="position-relative">
          <div class="icon text-warning rounded-circle text-center">
            <i class="flaticon-industry"></i>
          </div>
          <span class="d-block text-black mb-5 fw-semibold">Project Value</span>
          <span class="d-inline-block fs-md-15 fs-lg-16 text-muted">
            £{{ projectValue.toFixed(2) }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "LeadsInformation",
  props: {
    enquiryID: {
      type: Number,
      required: true
    },
    clientName: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    salesman: {
      type: String,
      required: true
    },
    businessArea: {
      type: String,
      required: true
    },
    projectDescription: {
      type: String,
      required: true
    },
    projectValue: {
      type: Number,
      required: true
    }
  }
});
</script>
