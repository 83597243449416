<template>
    <div
      class="mb-20 d-sm-flex align-items-center justify-content-between letter-spacing"
    >
      <h4 class="mb-5 mb-sm-0 fw-bold">{{ PageTitle }}</h4>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb mb-0 list-unstyled ps-0">
          <li class="breadcrumb-item">
            <router-link to="/" class="text-decoration-none text-black">
              Home
            </router-link>
          </li>
          <li
            v-for="(crumb, index) in breadcrumbs"
            :key="index"
            class="breadcrumb-item"
            :class="{ active: index === breadcrumbs.length - 1 }"
            aria-current="page"
          >
            <router-link
              v-if="index !== breadcrumbs.length - 1"
              :to="crumb.path"
              class="text-decoration-none text-black"
            >
              {{ crumb.name }}
            </router-link>
            <span v-else>{{ crumb.name }}</span>
          </li>
        </ol>
      </nav>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, computed } from "vue";
  import { useRoute, useRouter } from "vue-router";
  
  export default defineComponent({
    name: "DynamicBreadCrumb",
    props: ["PageTitle"],
    setup() {
      const route = useRoute();
      const router = useRouter();
  
      const breadcrumbs = computed(() => {
        const paths = route.path.split("/").filter((path) => path);
        const breadcrumbsArray = paths
          .map((path, index) => {
            const pathPart = `/${paths.slice(0, index + 1).join("/")}`;
            const routeMatch = router.resolve(pathPart);
  
            return {
              name: routeMatch.name || path.charAt(0).toUpperCase() + path.slice(1),
              path: pathPart,
            };
          })
          .filter(crumb => crumb.name !== 'ErrorPage'); // Exclude 'ErrorPage' segments
  
        return breadcrumbsArray;
      });
  
      return {
        breadcrumbs,
      };
    },
  });
  </script>
  
  <style scoped>
  .breadcrumb-item a {
    color: var(--bs-black);
  }
  
  .breadcrumb-item a:hover {
    text-decoration: underline;
  }
  </style>
  