<template>
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
      <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
        <div class="d-sm-flex align-items-center">
          <form class="search-box position-relative" @submit.prevent="handleSearch">
            <input type="text" class="form-control shadow-none text-black rounded-0 border-0" placeholder="Search project"
              v-model="searchQuery" />
            <button type="submit" class="bg-transparent text-primary transition p-0 border-0">
              <i class="flaticon-search-interface-symbol"></i>
            </button>
          </form>
        </div>
        <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
          <router-link to="/create-new-project"
            class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block d-inline-block text-decoration-none">
            Add Project
            <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          </router-link>
          <select class="project-select form-select shadow-none fw-semibold rounded-1 mt-10 mt-sm-0 ms-sm-10" v-model="pageSize" @change="fetchProjects(searchQuery)">
            <option :value="5">5</option>
            <option :value="10">10</option>
            <option :value="25">25</option>
            <option :value="50">50</option>
          </select>
          <div class="dropdown mt-10 mt-sm-0 ms-sm-10">
            <button class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
              type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="flaticon-dots"></i>
            </button>
            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);">
                  <i class="flaticon-view lh-1 me-8 position-relative top-1"></i>
                  View
                </a>
              </li>
              <li>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);">
                  <i class="flaticon-pen lh-1 me-8 position-relative top-1"></i>
                  Edit
                </a>
              </li>
              <li>
                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);">
                  <i class="flaticon-delete lh-1 me-8 position-relative top-1"></i>
                  Delete
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body p-15 p-sm-20 p-md-25">
        <div class="table-responsive">
          <table class="table text-nowrap align-middle mb-0">
            <thead>
              <tr>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
                  ENQUIRY ID
                </th>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                  CLIENT NAME
                </th>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                  STATUS
                </th>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                  SALESMAN
                </th>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                  PROJECT DESCRIPTION
                </th>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                  BUSINESS AREA
                </th>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0">
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="project in filteredProjects" :key="project.leadID">
                <th class="shadow-none title lh-1 fw-medium fs-md-15 fs-lg-16 ps-0">
                  {{ project.enquiryID }}
                </th>
                <td class="shadow-none lh-1">
                  {{ project.clientName }}
                </td>
                <td class="shadow-none lh-1 fw-medium">
                  {{ project.status }}
                </td>
                <td class="shadow-none lh-1 fw-medium">
                  {{ project.salesman }}
                </td>
                <td class="shadow-none lh-1 fw-medium">
                  {{ project.projectDescription }}
                </td>
                <td class="shadow-none lh-1 fw-medium">
                  {{ project.businessArea }}
                </td>
                <td class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0">
                  <div class="dropdown">
                    <button class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition" type="button"
                      data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="flaticon-dots"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <router-link :to="{ name: 'ProjectDetails', params: { id: project.leadID } }" class="dropdown-item d-flex align-items-center">
                          <i class="flaticon-view lh-1 me-8 position-relative top-1"></i>
                          View
                        </router-link>
                      </li>
                      <li>
                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);">
                          <i class="flaticon-pen lh-1 me-8 position-relative top-1"></i>
                          Edit
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);">
                          <i class="flaticon-delete lh-1 me-8 position-relative top-1"></i>
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination-area d-md-flex mt-15 mt-md-25 mb-0 justify-content-between align-items-center">
          <p class="mb-0 text-paragraph">
            Showing <span class="fw-bold">{{ filteredProjects.length }}</span> out of
            <span class="fw-bold">{{ totalProjects }}</span> results
          </p>
          <nav class="mt-15 mt-md-0">
            <ul class="pagination mb-0">
              <li class="page-item" :class="{ disabled: pageIndex === 1 }">
                <a class="page-link" href="#" aria-label="Previous" @click.prevent="changePage(pageIndex - 1)">
                  <i class="flaticon-chevron-1"></i>
                </a>
              </li>
              <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: page === pageIndex }">
                <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
              </li>
              <li class="page-item" :class="{ disabled: pageIndex === totalPages }">
                <a class="page-link" href="#" aria-label="Next" @click.prevent="changePage(pageIndex + 1)">
                  <i class="flaticon-chevron"></i>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { BASE_HSUITE_URL } from '@/core/constant/env';
  export default {
    name: "ProjectsList",
    data() {
      return {
        projects: [], // This will hold all projects fetched from the API
        searchQuery: "", // This will hold the search query
        filteredProjects: [], // This will hold projects filtered by the search query
        pageSize: 10, // Default page size
        pageIndex: 1, // Default page index
        totalProjects: 0, // Total number of projects
        totalPages: 1 // Total number of pages
      };
    },
    methods: {
      async fetchProjects(filter = "") {
        try {
          const response = await fetch(`${BASE_HSUITE_URL}/api/Project/GetProjectsByFilter/?filter=${filter}&pageSize=${this.pageSize}&pageIndex=${this.pageIndex}`);
          const data = await response.json();
          console.log(data); // Check the data structure
          this.projects = data.projects; // Access the projects array
          this.filteredProjects = data.projects; // Access the projects array
          this.totalProjects = data.totalProjects; // Assuming the API returns totalProjects
          this.totalPages = Math.ceil(this.totalProjects / this.pageSize); // Calculate total pages
        } catch (error) {
          console.error('Error fetching projects:', error);
        }
      },
      handleSearch(event) {
        event.preventDefault();
        const filter = this.searchQuery.trim();
        this.pageIndex = 1; // Reset to first page on search
        this.fetchProjects(filter);
      },
      changePage(page) {
        if (page > 0 && page <= this.totalPages) {
          this.pageIndex = page;
          this.fetchProjects(this.searchQuery);
        }
      },
      getStatusClass(status) {
        switch (status) {
          case 'In Process':
            return 'badge text-outline-primary fs-13';
          case 'On Hold':
            return 'badge text-outline-danger fs-13';
          case 'Completed':
            return 'badge text-outline-success fs-13';
          default:
            return 'badge text-outline-secondary fs-13';
        }
      }
    },
    created() {
      this.fetchProjects();
    }
  };
  </script>
  