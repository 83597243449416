<template>
    <div class="card mb-25 border-0 rounded-0 bg-white create-role-box">
      <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
        <form @submit.prevent="createRole">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Role Name
                </label>
                <input
                  v-model="roleName"
                  type="text"
                  class="form-control shadow-none rounded-0 text-black"
                  placeholder="e.g. Administrator"
                  required
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Role Description
                </label>
                <input
                  v-model="roleDescription"
                  type="text"
                  class="form-control shadow-none rounded-0 text-black"
                  placeholder="e.g. Administrator"
                  required
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="form-check-label">Is System Admin? &nbsp;</label>
                <input class="form-check-input shadow-none" type="checkbox" v-model="isSystemAdmin"/>
                
              </div>
            </div>
            <div class="col-md-12">
              <button
                class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                type="submit"
              >
                Create Role
              </button>

              <button @click="goToMainMenu" class="default-btn transition border-0 fw-medium text-white 
                pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 
                fs-md-15 fs-lg-16 m-5 bg-danger" type="button"> Cancel </button>
                </div>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref } from "vue";
  import HSuiteApiService from "@/core/services/hsuiteApi.service";
  import { useRouter } from "vue-router";
  import { useToast } from 'vue-toastification'
  
  export default defineComponent({
    name: "CreateRole",
    setup() {
      const roleName = ref("");
      const roleDescription=ref("");
      const isSystemAdmin=ref(false);

      const router = useRouter();
  
      const goToMainMenu = async () => {
        router.push({ name: "RoleListPage" });
      };
  
      const createRole = async () => {
        const toast = useToast();
        const payload = { name: roleName.value,description:roleDescription.value,isSystemAdmin: isSystemAdmin.value };
        
        HSuiteApiService.post(`/api/ElisaAuth/AddRole`, payload)
                  .then(response => {
                  if (response.status === 200) {
                    toast.success('Role created successfully!', {
                      timeout: 2000
                    })
                    goToMainMenu();
                  }
                  })
                  .catch(ex => {
                    const errorMessage = ex.response?.data || ex.message || "An unknown error occurred";
                    console.log(ex);
                    toast.error(`Failed to create role : ${errorMessage}`, {timeout: 2000,});
                  })

      };
  
      return {
        roleName,
        roleDescription,
        createRole,
        goToMainMenu,
        isSystemAdmin
      };
    },
  });
  </script>
  