import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import BootstrapVueNext from "bootstrap-vue-next";
import VueApexCharts from "vue3-apexcharts";
import { QuillEditor } from '@vueup/vue-quill'
import Vue3Prism from 'vue3-prism/lib/Vue3Prism.common.js'
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import VueSweetalert2 from 'vue-sweetalert2';
import Toast from "vue-toastification";
import { useToast } from 'vue-toastification';

import axios from 'axios';
import globalComponents from '@/components/global-components';
import store from './store'
import { VERIFY_AUTH, LOGOUT} from '@/store/auth.module'

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import "swiper/css";
import "swiper/css/bundle";
import 'flatpickr/dist/flatpickr.css';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import "vue3-prism/lib/Vue3Prism.css"
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-toastification/dist/index.css'

import "./assets/custom.scss";

import JwtService from '@/core/services/jwt.service'

router.beforeEach((to, from) => {

   // Check if the route's path starts with `/embed`
    if (to.path.startsWith('/embed/')) {
      // Allow navigation
      return true;
    }
    if (to.path == '/forcelogout')
    {
      store.dispatch(LOGOUT)
      return { name: 'LoginPage' }
    }
  
    Promise.all([store.dispatch(VERIFY_AUTH)]).then(() => {
      return true
    })
    
    if ((store.getters.currentPermissions == null || store.getters.currentPermissions == undefined) && to.name != 'LoginPage') {
      return { name: 'LoginPage' }
    }

    if ((!store.state.auth.isAuthenticated) && to.name != 'LoginPage') {
      return { name: 'LoginPage' }
    }
    
    window.axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          const toast = useToast();
          toast.error('Token expired. Please re-login.');
          store.dispatch(LOGOUT)
          return { name: 'LoginPage' }
        }
        return Promise.reject(error);
      }
    );
    

    if (
      to.meta != undefined &&
      !to.meta.isAllowAll &&
      to.meta.permission &&
      !store.getters.currentPermissions.includes(to.meta.permission)
    ) {
      return { name: 'EcommercePage' }
    }

    // Scroll page to top on every route change
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  })
  
const app = createApp(App).use(router);

// Extends the global Window interface to add a new property axios
declare global {
    interface Window {
      axios: typeof axios;
    }
}
window.axios = require('axios');

app.use(VueApexCharts);
app.use(BootstrapVueNext);
app.use(store);
app.component('QuillEditor', QuillEditor)
.use(Vue3Prism)
app.use(PrimeVue, {
    theme: {
        preset: Aura
    }
});

app.use(VueSweetalert2);
app.use(Toast);

globalComponents(app);

app.mount("#app");
