import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-297c5845"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-12" }
const _hoisted_3 = { class: "row mt-4" }
const _hoisted_4 = { class: "col-lg-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb")!
  const _component_OrderDataStatsGrid = _resolveComponent("OrderDataStatsGrid")!
  const _component_OrderDataBarChart = _resolveComponent("OrderDataBarChart")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BreadCrumb, { PageTitle: "Order Data Statistics" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.tableName)
          ? (_openBlock(), _createBlock(_component_OrderDataStatsGrid, {
              key: 0,
              tableName: _ctx.tableName,
              startDate: _ctx.startDate,
              endDate: _ctx.endDate,
              onDateChange: _ctx.handleDateChange
            }, null, 8, ["tableName", "startDate", "endDate", "onDateChange"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.tableName)
          ? (_openBlock(), _createBlock(_component_OrderDataBarChart, {
              key: 0,
              startDate: _ctx.startDate,
              endDate: _ctx.endDate,
              tableName: _ctx.tableName
            }, null, 8, ["startDate", "endDate", "tableName"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}