<template>
    <div class="card mb-25 border-0 rounded-0 bg-white">
      <div class="card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing">
        <div class="d-sm-flex align-items-center justify-content-between">
          <h5 class="card-title fw-bold mb-0">SKU Banding Results</h5>
          <div class="d-flex align-items-center">
            <select v-model="selectedBand" @change="updateHeadersAndFetchData" class="form-select me-3">
              <option value="bandResults_UnitVolume">Unit Volume</option>
              <option value="bandResults_VolumeInStock">Volume In Stock</option>
              <option value="bandResults_DailyCubicVelocity">Daily Cubic Velocity</option>
              <option value="bandResults_PicksPerDay">Picks Per Day</option>
            </select>
            <div class="dropdown">
              <button class="btn btn-secondary dropdown-toggle" type="button" id="exportMenu" data-bs-toggle="dropdown" aria-expanded="false">
                ...
              </button>
              <ul class="dropdown-menu" aria-labelledby="exportMenu">
                <li><a class="dropdown-item" href="#" @click.prevent="exportTable('jpg')">Export to JPG</a></li>
                <li><a class="dropdown-item" href="#" @click.prevent="exportTable('png')">Export to PNG</a></li>
              </ul>
            </div>
            <button class="btn btn-outline-secondary ms-3" @click="toggleSettingsModal">
              Column Settings
            </button>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-bordered table-hover mt-3" ref="skuTable">
            <thead>
              <tr>
                <th @click="sortTable('band')">Band</th>
                <th v-if="columnsVisibility.quantityOfSKUs" @click="sortTable('quantityOfSKUs')">Quantity of SKUs</th>
                <th v-if="columnsVisibility.meanVolume" @click="sortTable('meanVolume')">{{ volumeType }} (Mean)</th>
                <th v-if="columnsVisibility.medianVolume" @click="sortTable('medianVolume')">{{ volumeType }} (Median)</th>
                <th v-if="columnsVisibility.totalVolume" @click="sortTable('totalVolume')">{{ volumeType }} (Total)</th>
                <th v-if="columnsVisibility.percentageOfTotalVolume">Percentage of {{ volumeType }}</th>
                <th v-if="columnsVisibility.maxVolume" @click="sortTable('maxVolume')">{{ volumeType }} (Max)</th>
                <th v-if="columnsVisibility.minVolume" @click="sortTable('minVolume')">{{ volumeType }} (Min)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in sortedData" :key="item.band">
                <td>{{ item.band }}</td>
                <td v-if="columnsVisibility.quantityOfSKUs">{{ item.quantityOfSKUs }}</td>
                <td v-if="columnsVisibility.meanVolume">{{ item.meanVolume.toFixed(6) }}</td>
                <td v-if="columnsVisibility.medianVolume">{{ item.medianVolume.toFixed(6) }}</td>
                <td v-if="columnsVisibility.totalVolume">{{ item.totalVolume ? item.totalVolume.toFixed(6) : '-' }}</td>
                <td v-if="columnsVisibility.percentageOfTotalVolume">{{ calculatePercentage(item.totalVolume).toFixed(2) }}%</td>
                <td v-if="columnsVisibility.maxVolume">{{ item.maxVolume.toFixed(6) }}</td>
                <td v-if="columnsVisibility.minVolume">{{ item.minVolume.toFixed(6) }}</td>
              </tr>
              <tr v-if="bandData.length === 0">
                <td colspan="8" class="text-center">No data available</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
  
      <!-- Column Settings Modal -->
      <div class="modal fade" id="settingsModal" tabindex="-1" aria-labelledby="settingsModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="settingsModalLabel">Column Settings</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="quantityOfSKUs" v-model="columnsVisibility.quantityOfSKUs">
                <label class="form-check-label" for="quantityOfSKUs">Quantity of SKUs</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="meanVolume" v-model="columnsVisibility.meanVolume">
                <label class="form-check-label" for="meanVolume">{{ volumeType }} (Mean)</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="medianVolume" v-model="columnsVisibility.medianVolume">
                <label class="form-check-label" for="medianVolume">{{ volumeType }} (Median)</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="totalVolume" v-model="columnsVisibility.totalVolume">
                <label class="form-check-label" for="totalVolume">{{ volumeType }} (Total)</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="percentageOfTotalVolume" v-model="columnsVisibility.percentageOfTotalVolume">
                <label class="form-check-label" for="percentageOfTotalVolume">Percentage of {{ volumeType }}</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="maxVolume" v-model="columnsVisibility.maxVolume">
                <label class="form-check-label" for="maxVolume">{{ volumeType }} (Max)</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="minVolume" v-model="columnsVisibility.minVolume">
                <label class="form-check-label" for="minVolume">{{ volumeType }} (Min)</label>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary" @click="applyColumnSettings">Apply</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, computed, onMounted, watch } from "vue";
  import axios from "axios";
  import { BASE_HSUITE_URL } from "@/core/constant/env";
  import html2canvas from "html2canvas";
  import { Modal } from "bootstrap";
  
  interface BandData {
    band: number;
    quantityOfSKUs: number;
    meanVolume: number;
    medianVolume: number;
    totalVolume?: number;
    maxVolume: number;
    minVolume: number;
  }
  
  export default defineComponent({
    name: "SKUBandingTable",
    props: {
      tableName: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const selectedBand = ref("bandResults_UnitVolume");
      const bandData = ref<BandData[]>([]);
      const sortKey = ref("band");
      const sortOrder = ref("asc");
      const totalVolumeSum = ref(0);
      const volumeType = ref("Unit Volume");
      const columnsVisibility = ref({
        quantityOfSKUs: true,
        meanVolume: true,
        medianVolume: true,
        totalVolume: true,
        percentageOfTotalVolume: true,
        maxVolume: true,
        minVolume: true,
      });
  
      const updateHeadersAndFetchData = () => {
        // Map selectedBand to volumeType
        switch (selectedBand.value) {
          case "bandResults_UnitVolume":
            volumeType.value = "Unit Volume";
            break;
          case "bandResults_VolumeInStock":
            volumeType.value = "Volume In Stock";
            break;
          case "bandResults_DailyCubicVelocity":
            volumeType.value = "Daily Cubic Velocity";
            break;
          case "bandResults_PicksPerDay":
            volumeType.value = "Picks Per Day";
            break;
          default:
            volumeType.value = "Volume";
            break;
        }
        fetchData();
      };
  
      const fetchData = async () => {
        if (!props.tableName) {
          console.warn("fetchData: tableName is not defined or is empty.");
          return;
        }
  
        try {
          console.log(`fetchData: Fetching data for tableName: ${props.tableName}`);
          const response = await axios.get(`${BASE_HSUITE_URL}/api/SKUBanding/GetSKUBandingResults`, {
            params: {
              tableName: props.tableName,
            },
          });
  
          if (response.data.status === "Success") {
            bandData.value = response.data.data[selectedBand.value] || [];
            totalVolumeSum.value = calculateTotalVolumeSum();
          } else {
            console.warn("fetchData: API response status is not 'Success':", response.data.status);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      const calculateTotalVolumeSum = (): number => {
        return bandData.value.reduce((sum, item) => sum + (item.totalVolume || 0), 0);
      };
  
      const calculatePercentage = (volume: number | undefined): number => {
        if (!volume || totalVolumeSum.value === 0) return 0;
        return (volume / totalVolumeSum.value) * 100;
      };
  
      const sortTable = (key: keyof BandData) => {
        if (sortKey.value === key) {
          sortOrder.value = sortOrder.value === "asc" ? "desc" : "asc";
        } else {
          sortKey.value = key;
          sortOrder.value = "asc";
        }
      };
  
      const sortedData = computed(() => {
        return [...bandData.value].sort((a, b) => {
          let result = 0;
  
          if (sortKey.value === "band") {
            result = a.band - b.band;
          } else {
            result = (a[sortKey.value] as number) - (b[sortKey.value] as number);
          }
  
          return sortOrder.value === "asc" ? result : -result;
        });
      });
  
      const exportTable = async (format: string) => {
        const tableElement = document.querySelector(".table-responsive");
        if (!tableElement) return;
  
        const canvas = await html2canvas(tableElement as HTMLElement);
        const imgData = canvas.toDataURL(`image/${format}`);
  
        const link = document.createElement("a");
        link.href = imgData;
        link.download = `SKU_Banding_Results.${format}`;
        link.click();
      };
  
      const toggleSettingsModal = () => {
        const settingsModal = new Modal(document.getElementById('settingsModal')!);
        settingsModal.show();
      };
  
      const applyColumnSettings = () => {
        fetchData(); // Re-fetch data to apply any new settings if needed
      };
  
      onMounted(() => {
        console.log("onMounted: Component mounted, triggering fetchData");
        fetchData();
      });
  
      watch(
        () => selectedBand.value,
        (newBand) => {
          if (newBand) {
            console.log("watch: selectedBand changed, triggering updateHeadersAndFetchData for new band:", newBand);
            updateHeadersAndFetchData();
          }
        }
      );
  
      watch(
        () => props.tableName,
        (newTableName) => {
          if (newTableName) {
            console.log("watch: tableName changed, triggering fetchData for new tableName:", newTableName);
            fetchData();
          }
        }
      );
  
      return {
        selectedBand,
        bandData,
        sortKey,
        sortOrder,
        sortTable,
        sortedData,
        exportTable,
        calculatePercentage,
        columnsVisibility,
        volumeType,
        toggleSettingsModal,
        applyColumnSettings,
      };
    },
  });
  </script>
  
  <style scoped>
  .table-responsive {
    margin-top: 20px;
  }
  
  .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    border-collapse: collapse;
    cursor: pointer;
  }
  
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }
  
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }
  
  .table tbody + tbody {
    border-top: 2px solid #dee2e6;
  }
  
  .table-hover tbody tr:hover {
    color: #495057;
    background-color: rgba(0, 0, 0, 0.075);
  }
  </style>
  