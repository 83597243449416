<template>
  <BreadCrumb PageTitle="Reset Password" />
  <ResetPasswordBySystemAdmin />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ResetPasswordBySystemAdmin from "../../components/Users/ResetPasswordBySystemAdmin.vue";

export default defineComponent({
  name: "ResetPasswordBySystemAdminPage",
  components: {
    BreadCrumb,
    ResetPasswordBySystemAdmin,
  },
});
</script>