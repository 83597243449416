<template>
    <div class="container">
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'MainPage',
    components: {
    },
    data() {
      return {
        cardSize1: 'small',
        cardSize2: 'large',
      };
    },
  };
  </script>
  
  <style scoped>
  .container {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  </style>
  