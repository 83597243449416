<template>
    <div class="card mb-25 border-0 rounded-0 bg-white create-new-project-box">
      <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
        <form>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  User Layout Name
                </label>
                <input
                  type="text"
                  class="form-control shadow-none rounded-0 text-black"
                  v-model="userLayout.displayName"
                />
              </div>
            </div>
            <div class="col-md-12">
              <button
                class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                type="button" @click="SaveLayout"
              >
                Create User Layout
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref } from "vue";
  import HSuiteApiService from '@/core/services/hsuiteApi.service'
  import { GetLayouts } from '@/core/services/entities/authentication.service'
  import Swal from 'sweetalert2'

  
  export default defineComponent({
    name: "CreateNewUserLayout",
    setup: () => {
        const userLayout = ref({
            pageName: 'UserLayoutPage',
            displayName: '',
            userLayoutJson: ''
        })  

        const SaveLayout = () =>
        {
            HSuiteApiService.post('/api/ElisaAuth/AddUserLayout', userLayout.value)
                .then(response => {
                if (response.data.layoutId > 0) {
                    GetLayouts();
                    Swal.fire('Saved', 'You have successfully create new user layout.', 'success')
                }
                })
                .catch(ex => {
                  console.log(ex)
                })
        }
      return { userLayout, SaveLayout };
    },
  });
  </script>