<template>
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
      <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
        <div class="d-sm-flex align-items-center">
          <form class="search-box position-relative" @submit.prevent="handleSearch">
            <input type="text" class="form-control shadow-none text-black rounded-0 border-0" placeholder="Search role"
              v-model="searchQuery" />
            <button type="submit" class="bg-transparent text-primary transition p-0 border-0">
              <i class="flaticon-search-interface-symbol"></i>
            </button>
          </form>
        </div>
        <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
          <router-link to="/add-role" v-if="allowAdd"
            class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block text-decoration-none">
            Add Role
            <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          </router-link>
          <select class="project-select form-select shadow-none fw-semibold rounded-1 mt-10 mt-sm-0 ms-sm-10" v-model="pageSize" @change="fetchRoles(searchQuery)">
            <option :value="5">5</option>
            <option :value="10">10</option>
            <option :value="25">25</option>
            <option :value="50">50</option>
          </select>
        </div>
      </div>
      <div class="card-body p-15 p-sm-20 p-md-25">
        <div class="table-responsive" style="overflow: visible;">
          <table class="table text-nowrap align-middle mb-0">
            <thead>
              <tr>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
                  Role Name
                </th>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
                  Role Description
                </th>
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
                  Is System Admin
                </th>
                <!-- <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                  Action
                </th> -->
                <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0">
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="role in filteredRoles" :key="role.id">
                <th class="shadow-none title lh-1 fw-medium fs-md-15 fs-lg-16 ps-0">
                  {{ role.name }}
                </th>
                
                <th class="shadow-none title lh-1 fw-medium fs-md-15 fs-lg-16 ps-0">
                  {{ role.description }}
                </th>

                <th class="shadow-none title lh-1 fw-medium fs-md-15 fs-lg-16 ps-0">
                  {{ role.isSystemAdmin }}
                </th>

                <td class="shadow-none lh-1 fw-medium text-end pe-0">
                <div class="dropdown">
                  <button v-if="allowAdd" class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="flaticon-dots"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item d-flex align-items-center" href="#" @click.prevent="openUpdateRolePermissionPage(role)">
                        <i class="flaticon-edit lh-1 me-8 position-relative top-1"></i>
                        Update Permission
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item d-flex align-items-center" href="#" @click.prevent="openUpdateRolePage(role)">
                        <i class="flaticon-edit lh-1 me-8 position-relative top-1"></i>
                        Update Role
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="#" @click.prevent="deleteRole(role.id)"
                        ><i
                          class="flaticon-delete lh-1 me-8 position-relative top-1"
                        ></i>
                        Delete</a
                      >
                    </li>
                  </ul>
                </div>
              </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination-area d-md-flex mt-15 mt-md-25 mb-0 justify-content-between align-items-center">
          <p class="mb-0 text-paragraph">
            Showing <span class="fw-bold">{{ filteredRoles.length }}</span> out of
            <span class="fw-bold">{{ totalRoles }}</span> results
          </p>
          <nav class="mt-15 mt-md-0">
            <ul class="pagination mb-0">
              <li class="page-item" :class="{ disabled: pageIndex === 1 }">
                <a class="page-link" href="#" aria-label="Previous" @click.prevent="changePage(pageIndex - 1)">
                  <i class="flaticon-chevron-1"></i>
                </a>
              </li>
              <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: page === pageIndex }">
                <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
              </li>
              <li class="page-item" :class="{ disabled: pageIndex === totalPages }">
                <a class="page-link" href="#" aria-label="Next" @click.prevent="changePage(pageIndex + 1)">
                  <i class="flaticon-chevron"></i>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <Lightbox v-if="showUpdateLightbox" @close="closeUpdateLightbox">
        <UpdatePermission :id="selectedRole.id" :roleName="selectedRole.name" @permissionUpdated="handleDataStreamUpdated" @close="closeUpdateLightbox"/>
      </Lightbox>
    </div>

  </template>
  
  <script>
  import Lightbox from '@/components/Common/LightboxWrapper.vue';
  import HSuiteApiService from '@/core/services/hsuiteApi.service'
  import { GetRolesByFilter,DeleteRole } from '@/core/services/entities/authentication.service'
  import { useToast } from 'vue-toastification'
  import Swal from 'sweetalert2';
  import { mapGetters} from 'vuex'
  import breadcrumbMixin from '@/mixins/BreadcrumbMixin'

  export default {
    name: "RoleList",
    components: {
      Lightbox
    },
    data() {
      return {
        roles: [], 
        searchQuery: "", 
        filteredRoles: [], 
        pageSize: 10, 
        pageIndex: 1, 
        totalRoles: 0, 
        totalPages: 1, 
        showUpdateLightbox : false,
        selectedRole : {}
      };
    },
    mixins: [breadcrumbMixin],
    methods: {
      async fetchRoles(filter = "") {
        let data = await GetRolesByFilter(filter,this.pageSize,this.pageIndex);
        if(data)
        {
          this.filteredRoles = data.roles;
          this.totalRoles = data.totalRoles;
          this.totalPages = Math.ceil(this.totalRoles / this.pageSize);
        }

      },
      handleSearch(event) {
        event.preventDefault();
        const filter = this.searchQuery.trim();
        this.pageIndex = 1; 
        this.fetchRoles(filter);
      },
      changePage(page) {
        if (page > 0 && page <= this.totalPages) {
          this.pageIndex = page;
          this.fetchRoles(this.searchQuery);
        }
      },
      openUpdateRolePermissionPage(role) {

        this.$router.push({
          name: 'updateRolePermissionPage',
          params: {
            id: role.id,
            name: role.name
          }
        });

      },
      openUpdateRolePage(role) {
        console.log(role);
        this.$router.push({
          name: 'updateRolePage',
          params: {
            id: role.id,
            name: role.name,
            description: role.description || '-',
            isSystemAdmin: role.isSystemAdmin
          }
        });
      },
      async deleteRole(roleId) {
        const toast = useToast();

        // Show confirmation dialog using SweetAlert2
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {  
          if (result.isConfirmed) {
            try {
              const payload = { id: roleId };
              let response = await DeleteRole(payload);  
              if (response.status === 200) {
                toast.success('Role deleted successfully!', {
                  timeout: 2000,
                });
                this.fetchRoles();
              }
            } catch (ex) {
              const errorMessage = ex.response?.data || ex.message || "An unknown error occurred";
              toast.error(`Failed to delete role: ${errorMessage}`, {
                timeout: 2000,
              });
            }
          } else {
            toast.info('Role deletion canceled.', { timeout: 2000 });
          }
        });

      },
      openUpdateLightbox (role){
        this.selectedRole = role;
        this.showUpdateLightbox = true;
      },
      closeUpdateLightbox(){
        this.showUpdateLightbox = false;
      },
      handleDataStreamUpdated(){
        this.closeUpdateLightbox();
        // fetchDataStreams(searchQuery.value);
      }
    },
    computed:{...mapGetters(['allowAdd'])},
    created() {
      this.fetchRoles();
    }
  };
  </script>


  