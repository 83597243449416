<script>
import ProjectPanel from './ProjectPanel.vue';
import Swal from 'sweetalert2'
import { ref, onMounted } from 'vue';

export default {
  name: "ProjectLayout",
  components: {
    ProjectPanel
  },
  setup() {
    const data = ref({id: 0,
            type:'H',
            size:100,
            childs: []});

    onMounted(() => {
      data.value = JSON.parse(window.localStorage.getItem('layoutdata'));
      console.log(data.value);
    });

    const SaveLayout = () => 
    {
        const dataStr = JSON.stringify(data.value)
        window.localStorage.setItem('layoutdata', dataStr);
        Swal.fire('Saved', 'You have successfully save the layout.', 'success')
    }

    const AddPanel = () => 
    {
        data.value.childs.push({id:1,size:100})
    }
    return {
      data,
      SaveLayout,
      AddPanel
    };
  },
};
</script>
<template>
    <div class="button-container">
        <button v-if="data.childs.length == 0" style="width: 10%;margin-bottom: 10px;" @click="AddPanel()" class="text-white bg-success">Add Panel <i class="flaticon-plus"></i></button>
        <button style="width: 10%;margin-bottom: 10px;" @click="SaveLayout()" class="text-white bg-success">Save Layout <i class="ph ph-floppy-disk"></i></button>
    </div>
    <div class="card" style="height: 800px; min-width: 1200px;">
        <ProjectPanel :data="data">
        </ProjectPanel>
    </div>
</template>

<style>
  .button-container {
        display: flex;
        gap: 5px;
        margin-right: 5px;
        margin-top: 5px;
        text-decoration: none !important;
        font-weight: 500 !important;
        border: 0;
    }
</style>
  