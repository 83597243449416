import HAuthApiService from '@/core/services/hauthApi.service'
import HSuiteApiService from '@/core/services/hsuiteApi.service'

async function GetSystemVarsionHSuite() {
    let result = null;
    await HSuiteApiService.get('/api/SystemParameter/getSystemVersion').then(response => {
      result = response.data;
    })
    .catch(ex => {
      console.log(ex);
    })
  
    return result;
}

async function GetSystemVarsionHAuth() {
    let result = null;
    await HAuthApiService.get('/api/SystemParameter/getSystemVersion').then(response => {
      result = response.data;
    })
    .catch(ex => {
      console.log(ex);
    })
  
    return result;
}


export { 
    GetSystemVarsionHSuite,
    GetSystemVarsionHAuth
}