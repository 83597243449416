<template>
  <div class="add-data-stream">
    <h2>Create Data Stream</h2>
    <div class="form-group mb-4">
      <label for="name" class="form-label">Name</label>
      <input
        type="text"
        id="name"
        class="form-control"
        v-model="name"
        placeholder="Enter data stream name"
      />
    </div>
    <div class="form-group mb-4">
      <label for="description" class="form-label">Description</label>
      <textarea
        id="description"
        class="form-control"
        v-model="description"
        placeholder="Enter data stream description"
        rows="4"
      ></textarea>
    </div>
    <div class="form-buttons">
      <button class="btn btn-primary" @click="addDataStream">Add Data Stream</button>
      <button class="btn btn-secondary" @click="$emit('close')">Cancel</button>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import axios from 'axios';
import { BASE_HSUITE_URL } from '@/core/constant/env';

export default defineComponent({
  name: 'CreateDataStream',
  props: {
    leadID: {
      type: Number,
      required: true
    }
  },
  setup(props, { emit }) {
    const name = ref('');
    const description = ref('');

    const addDataStream = async () => {
      console.log('Adding data stream...');
      console.log('LeadID:', props.leadID);
      console.log('Name:', name.value);
      console.log('Description:', description.value);

      try {
        const response = await axios.post(`${BASE_HSUITE_URL}/api/DataStream/CreateDataStream`, {
          leadID: props.leadID,
          name: name.value,
          description: description.value
        });

        console.log('API Response:', response.data);

        if (response.data.status === 'Success') {
          alert(response.data.message);
          name.value = '';
          description.value = '';
          emit('dataStreamAdded'); // Emit the event to notify the parent component
          emit('close'); // Close the lightbox after adding data stream
        } else {
          console.error('Error response:', response.data);
          alert('Failed to add data stream.');
        }
      } catch (error) {
        console.error('Error adding data stream:', error);
        alert('Error adding data stream.');
      }
    };

    return {
      name,
      description,
      addDataStream
    };
  }
});
</script>

<style scoped>
.add-data-stream {
  width: 500px; /* Double the size */
  height: 400px; /* Double the size */
  padding: 40px; /* Increase padding */
  background-color: #fff; /* Ensure background is white */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 1rem;
}

.form-label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-control {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.btn {
  padding: 0.5rem 1rem;
}

.btn-secondary {
  background-color: #6c757d;
  color: #fff;
}

.btn-secondary:hover {
  background-color: #5a6268;
}
</style>
