<template>
    <BreadCrumb PageTitle="Retrieval Analysis Results" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card mb-25 border-0 rounded-0 bg-white sales-pos-location-box">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <RetrievalSimulationAnalysis :tableName="tableName" @dateChange="handleDateChange" />
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="card mb-25 border-0 rounded-0 bg-white sales-pos-location-box">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <RetrievalSimulationPercentileAnalysis :tableName="tableName" :startDate="startDate" :endDate="endDate" />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import BreadCrumb from '../../../components/Common/DynamicBreadCrumb.vue';
  import RetrievalSimulationAnalysis from '../../../components/ELSA/RetrievalAnalysis/RetrievalSimulationAnalysis.vue';
  import RetrievalSimulationPercentileAnalysis from '../../../components/ELSA/RetrievalAnalysis/RetrievalSimulationPercentileAnalysis.vue';
  
  export default defineComponent({
    name: 'RetrievalAnalysisResultsPage',
    components: {
      BreadCrumb,
      RetrievalSimulationAnalysis,
      RetrievalSimulationPercentileAnalysis,
    },
    setup() {
      const route = useRoute();
      const tableName = ref('');
      const startDate = ref('2020-01-01');
      const endDate = ref(new Date().toISOString().split('T')[0]);
  
      const handleDateChange = (newStartDate: string, newEndDate: string) => {
        startDate.value = newStartDate;
        endDate.value = newEndDate;
      };
  
      onMounted(() => {
        const tableNameParam = route.params.tableName;
        if (typeof tableNameParam === 'string') {
          tableName.value = tableNameParam;
        }
      });
  
      return {
        tableName,
        startDate,
        endDate,
        handleDateChange,
      };
    },
  });
  </script>
  
  <style scoped>
  .card {
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
  }
  
  .letter-spacing {
    letter-spacing: 0.5px;
  }
  
  .h-100 {
    height: 100%;
  }
  </style>
  