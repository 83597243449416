<template>
    <DynamicBreadCrumb PageTitle="Create Work Order Request" />
    <WorkOrderRequestForm />
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  import DynamicBreadCrumb from "@/components/Common/DynamicBreadCrumb.vue";
  import WorkOrderRequestForm from "@/components/WorkOrderManagement/CreateWorkOrder.vue";
  
  export default defineComponent({
    name: "CreateWorkOrderPage",
    components: {
      DynamicBreadCrumb,
      WorkOrderRequestForm,
    },
  });
  </script>
  