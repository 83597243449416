
import HSuiteApiService from '@/core/services/hsuiteApi.service'

async function GetAllEmbedPages(params) {
  let result = null;
  await HSuiteApiService.filter('/api/ElisaEmbed/GetAllEmbedPages', params).then(response => {
    result = response.data;
  })
  .catch(ex => {
    console.log(ex);
  })

  return result;
}

async function DeleteEmbed(payLoad) {
  let result = null;
  await HSuiteApiService.post(`/api/ElisaEmbed/DeleteEmbed`, payLoad).then(response => {
    result = response;
    console.log(response)
  })
  .catch(ex => {
    console.log(ex);
    throw ex;
  })

  return result;
}

export { 
    GetAllEmbedPages,
    DeleteEmbed
}
  