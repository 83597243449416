<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative" @submit.prevent="handleSearch">
          <input type="text" class="form-control shadow-none text-black rounded-0 border-0" placeholder="Search project"
            v-model="searchQuery" />
          <button type="submit" class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <router-link to="/create-new-project" v-if="allowAdd"
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block text-decoration-none">
          Add Project
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </router-link>
        <select class="project-select form-select shadow-none fw-semibold rounded-1 mt-10 mt-sm-0 ms-sm-10" v-model="pageSize" @change="fetchProjects(searchQuery)">
          <option :value="5">5</option>
          <option :value="10">10</option>
          <option :value="25">25</option>
          <option :value="50">50</option>
        </select>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
                ENQUIRY ID
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                CLIENT NAME
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                STATUS
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                SALESMAN
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                PROJECT DESCRIPTION
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                BUSINESS AREA
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0">
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="project in filteredProjects" :key="project.leadID">
              <th class="shadow-none title lh-1 fw-medium fs-md-15 fs-lg-16 ps-0">
                {{ project.enquiryID }}
              </th>
              <td class="shadow-none lh-1">
                {{ project.clientName }}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                {{ project.status }}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                {{ project.salesman }}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                {{ project.projectDescription }}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                {{ project.businessArea }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0">
                <div class="dropdown">
                  <button class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition" type="button"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="flaticon-dots"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a @click.prevent="viewDataStreams(project.leadID)" class="dropdown-item d-flex align-items-center" href="#">
                        <i class="flaticon-view lh-1 me-8 position-relative top-1"></i>
                        View Data Streams
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination-area d-md-flex mt-15 mt-md-25 mb-0 justify-content-between align-items-center">
        <p class="mb-0 text-paragraph">
          Showing <span class="fw-bold">{{ filteredProjects.length }}</span> out of
          <span class="fw-bold">{{ totalProjects }}</span> results
        </p>
        <nav class="mt-15 mt-md-0">
          <ul class="pagination mb-0">
            <li class="page-item" :class="{ disabled: pageIndex === 1 }">
              <a class="page-link" href="#" aria-label="Previous" @click.prevent="changePage(pageIndex - 1)">
                <i class="flaticon-chevron-1"></i>
              </a>
            </li>
            <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: page === pageIndex }">
              <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
            </li>
            <li class="page-item" :class="{ disabled: pageIndex === totalPages }">
              <a class="page-link" href="#" aria-label="Next" @click.prevent="changePage(pageIndex + 1)">
                <i class="flaticon-chevron"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>

import HSuiteApiService from '@/core/services/hsuiteApi.service'
import breadcrumbMixin from '@/mixins/BreadcrumbMixin'

export default {
  name: "ProjectsList",
  data() {
    return {
      projects: [], // This will hold all projects fetched from the API
      searchQuery: "", // This will hold the search query
      filteredProjects: [], // This will hold projects filtered by the search query
      pageSize: 10, // Default page size
      pageIndex: 1, // Default page index
      totalProjects: 0, // Total number of projects
      totalPages: 1 // Total number of pages
    };
  },
  mixins: [breadcrumbMixin],
  methods: {
    async fetchProjects(filter = "") {
      
      HSuiteApiService.get(`/api/Project/GetProjectsByFilter`,`?filter=${filter}&pageSize=${this.pageSize}&pageIndex=${this.pageIndex}`)
                        .then(response=>{
                          const data = response.data;
                          if (data && data.projects) {
                            this.projects = data.projects;
                            console.log(this.projects); 
                            this.filteredProjects = data.projects;
                            this.totalProjects = data.totalProjects;
                            this.totalPages = Math.ceil(this.totalProjects / this.pageSize);
                          }
                        }).catch(error => {
                          console.error('Error fetching projects:', error);
                        });

    },
    handleSearch(event) {
      event.preventDefault();
      const filter = this.searchQuery.trim();
      this.pageIndex = 1; // Reset to first page on search
      this.fetchProjects(filter);
    },
    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.pageIndex = page;
        this.fetchProjects(this.searchQuery);
      }
    },
    viewDataStreams(leadID) {
      this.$router.push({ name: 'Data Stream Overview', params: { leadID } });
    }
  },
  created() {
    this.fetchProjects();
  }
};
</script>
