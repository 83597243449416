<template>
  <BreadCrumb PageTitle="SKU Data Table" />

    <div class="row">
      <div class="col-xxl-12">
          <SKUDataTable
            :headers="headers"
            :tableName="tableName"
            :filters="filters"
          />
        </div>
      </div>
    
</template>

<script>
import SKUDataTable from '@/components/ELSA/SKUDataTable/SKUDataTable.vue';
import BreadCrumb from "@/components/Common/DynamicBreadCrumb.vue";

import { useRoute } from 'vue-router';

export default {
  components: {
    BreadCrumb,
    SKUDataTable,
  },
  data() {
    return {
      filters: {},
      headers: {
        basic: ['SKUID'],
        characteristics: ['ItemDescription', 'SuggestedSystem'],
        stock: ['UnitsInStock', 'VolumeInStock'],
        sales: ['VolumePerDay'],
        unit: ['UnitWidth', 'UnitDepth', 'UnitHeight', 'UnitWeight','UnitDensity', 'UnitVolume',],
        inner: ['InnerWidth', 'InnerDepth', 'InnerHeight', 'InnerWeight'],
        outer: ['OuterWidth', 'OuterDepth', 'OuterHeight', 'OuterWeight'],
        misc: ['Miscellaneous1', 'Miscellaneous2', 'Miscellaneous3', 'Miscellaneous4', 'Miscellaneous5'],
        band: ['Band_UnitVolume', 'Band_DailyCubicVelocity', 'Band_VolumeInStock'],
        systems: [
          'Optimal_HighDensityDrawers', 'Feasible_HighDensityDrawers', 
          'Optimal_BinShelving', 'Feasible_BinShelving', 
          'Optimal_DeckedRacking', 'Feasible_DeckedRacking', 
          'Optimal_CartonFlowRack', 'Feasible_CartonFlowRack', 
          'Optimal_PalletRackPosition', 'Feasible_PalletRackPosition', 
          'Optimal_PalletFlowRack', 'Feasible_PalletFlowRack', 
          'Optimal_NONE', 'Feasible_NONE'
        ]
      },
    };
  },
  computed: {
    tableName() {
      return this.$route.params.tableName;
    }
  }
};
</script>
