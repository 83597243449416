<template>
    <BreadCrumb PageTitle="SKU Banding Results" />
    <div class="row">
      <div class="col-xxl-12">
        <div class="row">
          <div class="col-lg-12 d-flex">
            <div class="card mb-25 border-0 rounded-0 bg-white flex-grow-1">
              <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
                <SKUBandingTable :tableName="tableName" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import BreadCrumb from '../../../components/Common/DynamicBreadCrumb.vue';
  import SKUBandingTable from '../../../components/ELSA/SKUBanding/SKUBandingTable.vue';
  
  export default defineComponent({
    name: 'SKUBandingResultsPage',
    components: {
      BreadCrumb,
      SKUBandingTable,
    },
    setup() {
      const route = useRoute();
      const tableName = ref<string | null>(null);
  
      onMounted(() => {
        const tableNameParam = route.params.tableName;
        if (typeof tableNameParam === 'string') {
          tableName.value = tableNameParam;
        }
      });
  
      return {
        tableName,
      };
    },
  });
  </script>
  
  <style scoped>
  .card {
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
  }
  
  .letter-spacing {
    letter-spacing: 0.5px;
  }
  
  .h-100 {
    height: 100%;
  }
  </style>
  