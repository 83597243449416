<template>
    <BreadCrumb PageTitle="Role List" />
    <RoleList />
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import RoleList from "../../components/Roles/RoleList.vue";
  
  export default defineComponent({
    name: "RolesPage",
    components: {
      BreadCrumb,
      RoleList,
    },
  });
  </script>