import JwtService from '@/core/services/jwt.service'
import { BASE_HSUITE_URL } from '@/core/constant/env';

/**
 * Service to call HTTP request via Axios
 */
const hsuiteApiService = {
  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    window.axios.defaults.headers.common.Authorization = `Token ${JwtService.getToken()}`
  },

  query(resource, params) {
    return window.axios.get(resource, params).catch(error => {
      throw new Error(`[KT] ApiService ${error}`)
    })
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = '') {
    return window.axios.get(`${BASE_HSUITE_URL}${resource}/${slug}`).catch(error => {
      throw new Error(`[KT] ApiService ${error}`)
    })
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  filter(resource, params) {
    return window.axios.get(`${BASE_HSUITE_URL}${resource}`, { params }).catch(error => {
      throw new Error(`[KT] ApiService ${error}`)
    })
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return window.axios.post(`${BASE_HSUITE_URL}${resource}`, params)
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return window.axios.put(`${BASE_HSUITE_URL}${resource}/${slug}`, params)
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return window.axios.put(`${BASE_HSUITE_URL}${resource}`, params)
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return window.axios.delete(`${BASE_HSUITE_URL}${resource}`).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`)
    })
  }
}

export default hsuiteApiService
