<template>
  <div class="card mb-25 border-0 rounded-0 bg-white create-role-box">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <form @submit.prevent="updateRole">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Role Name
              </label>
              <input
                v-model="role.name"
                type="text"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="e.g. Administrator"
                required
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Role Description
              </label>
              <input
                v-model="role.description"
                type="text"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="e.g. Administrator"
                required
              />
            </div>
          </div>
          <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="form-check-label">Is System Admin? &nbsp;</label>
                <input class="form-check-input shadow-none" type="checkbox" v-model="role.isSystemAdmin"/>
                
              </div>
            </div>
          <div class="col-md-12">
            <button
              class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
              Update Role
            </button>

            <button @click="goToMainMenu" class="default-btn transition border-0 fw-medium text-white 
              pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 
              fs-md-15 fs-lg-16 m-5 bg-danger" type="button"> Cancel </button>
              </div>
        </div>
      </form>
    </div>
  </div>
</template>
  
  <script>
  import { defineComponent} from 'vue';
  import { useRoute, useRouter } from "vue-router";
  import { UpdateRole } from '@/core/services/entities/authentication.service'
  import { useToast } from 'vue-toastification'

  export default defineComponent({
    name: 'UpdateRole',
    
    setup() {
      const route = useRoute();
      const router = useRouter();
      const role = route.params;

      const goToMainMenu = async () => {
        router.push({ name: "RoleListPage" });
      };

      const updateRole = async () => {
        const toast = useToast();

        try {
              const payload = { 
                id: role.id,
                name:role.name,
                description:role.description,
                isSystemAdmin:role.isSystemAdmin
              };
              
              let response = await UpdateRole(payload);  
              if (response.status === 200) {
                toast.success('Role updated successfully!', {
                  timeout: 2000,
                });
                goToMainMenu();
              }
            } catch (ex) {
              const errorMessage = ex.response?.data || ex.message || "An unknown error occurred";
              toast.error(`Failed to update role: ${errorMessage}`, {
                timeout: 2000,
              });
            }
  
      };

      return {
        role,
        goToMainMenu,
        updateRole
      };
    }
  });
  </script>

  