import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      isReady: false
    }
  },
  computed: {
    ...mapGetters(['allowAdd', 'allowDelete', 'allowEdit', 'allowPrint', 'allowUpload', 'allowView','currentPermissions','allowResetPassword'])
  },
  mounted() {
    this.isReady = true
  }
}
