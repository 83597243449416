<template>
  <div>
    <BreadCrumb PageTitle="Data Table Overview" />
    <div class="row">
      <div class="col-lg-12">
        <DataTableList :dataStreamID="dataStreamID" :leadID="leadID" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import BreadCrumb from "../../components/Common/DynamicBreadCrumb.vue";
import DataTableList from "../../components/ELSA/TableManagement/DataTableList.vue";

export default defineComponent({
  name: "DataTablePage",
  components: {
    BreadCrumb,
    DataTableList,
  },
  setup() {
    const route = useRoute();
    const dataStreamID = ref<number | null>(null);
    const leadID = ref<number | null>(null);

    onMounted(() => {
      const dataStreamIdParam = route.params.dataStreamID;
      const leadIdParam = route.params.leadID;

      console.log("Route Params:", route.params);

      if (typeof dataStreamIdParam === "string") {
        dataStreamID.value = parseInt(dataStreamIdParam, 10);
        console.log("Data Stream ID:", dataStreamID.value);  // Debug log
      } else if (Array.isArray(dataStreamIdParam)) {
        dataStreamID.value = parseInt(dataStreamIdParam[0], 10); // handle array case
        console.log("Data Stream ID from array:", dataStreamID.value);  // Debug log
      }

      if (typeof leadIdParam === "string") {
        leadID.value = parseInt(leadIdParam, 10);
        console.log("Lead ID:", leadID.value);  // Debug log
      } else if (Array.isArray(leadIdParam)) {
        leadID.value = parseInt(leadIdParam[0], 10); // handle array case
        console.log("Lead ID from array:", leadID.value);  // Debug log
      }
    });

    return {
      dataStreamID,
      leadID,
    };
  },
});
</script>
