import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mt-4" }
const _hoisted_2 = { class: "col-lg-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrderDataBarChart = _resolveComponent("OrderDataBarChart")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.embedId != null)
          ? (_openBlock(), _createBlock(_component_OrderDataBarChart, {
              key: 0,
              startDate: _ctx.startDate,
              endDate: _ctx.endDate,
              isEmbed: true,
              embedId: _ctx.embedId
            }, null, 8, ["startDate", "endDate", "embedId"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}