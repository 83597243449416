<script>
import UserLayoutPanel from './UserLayoutPanel.vue';
import { useRoute } from 'vue-router';
import Swal from 'sweetalert2'
import { ref, onMounted } from 'vue';
import { GetUserLayout, UpdateUserLayout } from '@/core/services/entities/authentication.service'

export default {
  name: "UserLayout",
  components: {
    UserLayoutPanel
  },
  setup() {
    const route = useRoute();
    
    const userLayout = ref({});
    const layoutObj = ref({id: 0,
            type:'H',
            size:100,
            childs: []});

    onMounted(async () => {
       userLayout.value = await GetUserLayout(route.params.id);
       if(userLayout.value && userLayout.value.userLayoutJSON != null && userLayout.value.userLayoutJSON != '')
       {
           layoutObj.value = JSON.parse(userLayout.value.userLayoutJSON);
       }
      
    });

    const SaveLayout = async () => 
    {
        userLayout.value.userLayoutJSON = JSON.stringify(layoutObj.value)
        await UpdateUserLayout(userLayout.value)
        Swal.fire('Saved', 'You have successfully save the layout.', 'success')
    }

    const AddPanel = () => 
    {
      layoutObj.value.childs.push({id:1,size:100})
    }
    return {
      userLayout,
      layoutObj,
      SaveLayout,
      AddPanel
    };
  },
};
</script>
<template>
    <div class="button-container">
      <input type="text" v-model="userLayout.displayName" class="form-control shadow-none rounded-0 text-black" style="width: 30%;margin-bottom: 10px;"/>
        <button v-if="layoutObj.childs.length == 0" style="width: 10%;margin-bottom: 10px;" @click="AddPanel()" class="text-white bg-success">Add Panel <i class="flaticon-plus"></i></button>
        <button style="width: 10%;margin-bottom: 10px;" @click="SaveLayout()" class="text-white bg-success">Save Layout <i class="ph ph-floppy-disk"></i></button>
    </div>
    <div class="card" style="height: 800px; min-width: 1200px;">
        <UserLayoutPanel :data="layoutObj">
        </UserLayoutPanel>
    </div>
</template>

<style>
  .button-container {
        display: flex;
        gap: 5px;
        margin-right: 5px;
        margin-top: 5px;
        text-decoration: none !important;
        font-weight: 500 !important;
        border: 0;
    }
</style>
  