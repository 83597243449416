import { createStore } from 'vuex'
import main from './main'
import app from './app.module'
import auth from './auth.module'
// import master from './master.module'

const store = createStore({
  modules: {
    main,
    app,
    auth
  }
})

export function useStore() {
  return store
}

export default store
