<template>
    <div>
        <div class="row mt-4">
            <div class="col-lg-12">
            <OrderDataBarChart v-if="embedId != null" :startDate="startDate" :endDate="endDate" :isEmbed=true :embedId="embedId"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
  import { defineComponent, ref, onMounted, watch } from "vue";
  import { useRoute } from "vue-router";
  import OrderDataBarChart from "@/components/ELSA/OrderData/OrderDataBarChart.vue";
  
  export default defineComponent({
    name: "EmbedOrderDataOverview",
    components: {
      OrderDataBarChart,
    },
    setup() {
      const route = useRoute();
      const tableName = ref<string | null>(null);
      const embedId = ref<string | null>(null);
      const startDate = ref("");
      const endDate = ref("");
  
      const handleDateChange = (newDates) => {
        startDate.value = newDates.startDate;
        endDate.value = newDates.endDate;
        console.log("Dates updated in OrderDataStatsPage:", startDate.value, endDate.value);
      };

      onMounted(() => {
        const embedIdParam = route.params.embedId;
        if (typeof embedIdParam === "string") {
          embedId.value = embedIdParam;
        } else if (Array.isArray(embedIdParam)) {
          embedId.value = embedIdParam[0];
        }
      });

      return {
        tableName,
        startDate,
        endDate,
        handleDateChange,
        embedId
      };
    },
  });
  </script>