<template>
  <BreadCrumb PageTitle="Update Role Permission" />
  <UpdateRole />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import UpdateRole from "../../components/Roles/UpdateRole.vue";

export default defineComponent({
  name: "UpdateRolePage",
  components: {
    BreadCrumb,
    UpdateRole,
  },
});
</script>