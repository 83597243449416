<template>
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
    <div class="mb-15 mb-sm-0 d-sm-flex align-items-center justify-content-between">
      <div class="title">
        <span class="fw-medium text-muted fs-13 d-block mb-5 text-uppercase">
          Required SKU Retrievals Over Time
        </span>
      </div>
      <div>
        <input type="date" v-model="startDate" @change="onDateChange" class="date-picker" />
        <input type="date" v-model="endDate" @change="onDateChange" class="date-picker" />
      </div>
    </div>
    <div class="chart">
      <apexchart
        type="line"
        height="374"
        id="retrievalAnalysisChart"
        :options="chartOptions"
        :series="chartData"
      ></apexchart>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, nextTick, onMounted, watch, toRefs } from 'vue';
import axios from 'axios';
import { ApexOptions } from 'apexcharts';
import ApexCharts from 'apexcharts'; // Ensure this line is present
import { BASE_HSUITE_URL } from '@/core/constant/env';

interface ChartSeries {
  name: string;
  data: [number, number][];
}

export default defineComponent({
  name: 'RetrievalSimulationAnalysis',
  props: {
    tableName: {
      type: String,
      required: true,
    },
  },
  emits: ['dateChange'],
  setup(props, { emit }) {
    const { tableName } = toRefs(props);
    const chartData = ref<ChartSeries[]>([]);
    const chartOptions = ref<ApexOptions>({
      chart: {
        height: 374,
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      colors: ['#6560F0', '#6FD3F7', '#FF5733'],
      legend: {
        position: 'top',
        fontSize: '14px',
        fontWeight: 500,
        horizontalAlign: 'center',
        fontFamily: 'Red Hat Display, sans-serif',
        labels: {
          colors: '#8E8DA1',
        },
        markers: {
          offsetY: -1,
          offsetX: -4,
          height: 15,
          width: 15,
        },
        itemMargin: {
          horizontal: 10,
        },
      },
      grid: {
        show: true,
        strokeDashArray: 5,
        borderColor: '#d9e9ef',
      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy',
        },
        style: {
          fontSize: '14px',
          fontFamily: 'Red Hat Display, sans-serif',
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          show: true,
          format: 'dd MMM yyyy',
          style: {
            fontFamily: 'Red Hat Display, sans-serif',
            colors: '#9C9AB6',
            fontSize: '14px',
            fontWeight: 500,
          },
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        show: true,
        labels: {
          style: {
            fontFamily: 'Red Hat Display, sans-serif',
            colors: ['#9C9AB6'],
            fontSize: '14px',
            fontWeight: 500,
          },
        },
      },
      annotations: {
        xaxis: [],
      },
    });

    const selectedPageSize = ref(1095);
    const currentPage = ref(1);
    const startDate = ref('2020-01-01');
    const endDate = ref(new Date().toISOString().split('T')[0]);
    let initialLoad = true;

    const fetchData = async (updateDatePickers = false) => {
      try {
        console.log(tableName.value + " & " + startDate.value + " & " + endDate.value);

        const response = await axios.post(
          `${BASE_HSUITE_URL}/api/RetrievalQuantityAnalysis/GetRQAAggregatedData`,
          {
            TableName: tableName.value,
            PageSize: selectedPageSize.value,
            PageNumber: currentPage.value,
            StartDate: startDate.value,
            EndDate: endDate.value,
          }
        );

        const waveDates = response.data.data.map((item: any) => new Date(item.waveDate).getTime());
        const totalLines = response.data.data.map((item: any) => item.totalLines);
        const uniqueSKUs = response.data.data.map((item: any) => item.uniqueSKUs);
        const totalUnits = response.data.data.map((item: any) => item.totalUnits);

        chartData.value = [
          { name: 'Total Lines', data: waveDates.map((date, index) => [date, totalLines[index]]) },
          { name: 'Unique SKUs', data: waveDates.map((date, index) => [date, uniqueSKUs[index]]) },
          { name: 'Total Units', data: waveDates.map((date, index) => [date, totalUnits[index]]) },
        ];

        // Find the peak Unique SKUs value
        const maxUniqueSKUsIndex = uniqueSKUs.indexOf(Math.max(...uniqueSKUs));
        const maxUniqueSKUsDate = waveDates[maxUniqueSKUsIndex];

        console.log(updateDatePickers + " & " + response.data.data.length);

        // Update date pickers with the actual date range if requested
        if (updateDatePickers && response.data.data.length > 0) {
          startDate.value = new Date(Math.min(...waveDates)).toISOString().split('T')[0];
          endDate.value = new Date(Math.max(...waveDates)).toISOString().split('T')[0];
          emit('dateChange', startDate.value, endDate.value);
        }

        if (chartOptions.value) {
  if (!chartOptions.value.annotations) {
    chartOptions.value.annotations = { xaxis: [] };
  }

  chartOptions.value.annotations.xaxis = [
    {
      x: maxUniqueSKUsDate,
      borderColor: '#FF5733',
      label: {
        style: {
          color: '#fff',
          background: '#FF5733',
        },
        text: 'Peak Unique SKUs',
      },
    },
  ];
}



        nextTick(() => {
          ApexCharts.exec('retrievalAnalysisChart', 'updateOptions', chartOptions.value);
          ApexCharts.exec('retrievalAnalysisChart', 'updateSeries', chartData.value);
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const onDateChange = () => {
      fetchData(false);
      emit('dateChange', startDate.value, endDate.value);
    };

    onMounted(() => {
      if (tableName.value) {
        fetchData(true).then(() => {
          initialLoad = false;
        });
      }
    });

    watch(tableName, (newTableName) => {
      if (newTableName && initialLoad) {
        fetchData(true).then(() => {
          initialLoad = false;
        });
      }
    });

    return {
      selectedPageSize,
      currentPage,
      startDate,
      endDate,
      chartData,
      chartOptions,
      fetchData,
      onDateChange,
    };
  },
});
</script>

<style scoped>
.date-picker {
  margin-right: 10px;
  padding: 10px;
  font-size: 16px;
}
</style>
