<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-header bg-white p-3">
      <h5 class="mb-0">Data Streams</h5>
    </div>
    <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative" @submit.prevent="handleSearch">
          <input type="text" class="form-control shadow-none text-black rounded-0 border-0" placeholder="Search DataStream"
            v-model="searchQuery" />
          <button type="submit" class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <button @click="openCreateLightbox" class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block text-decoration-none">
          Add DataStream
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>
        <select class="project-select form-select shadow-none fw-semibold rounded-1 mt-10 mt-sm-0 ms-sm-10" v-model="pageSize" @change="fetchDataStreams(searchQuery)">
          <option :value="5">5</option>
          <option :value="10">10</option>
          <option :value="25">25</option>
          <option :value="50">50</option>
        </select>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0" style="min-height: 300px;">
          <thead>
            <tr>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
                NAME
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 description-column">
                DESCRIPTION
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                CREATED AT
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                UPDATED AT
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                ACTIONS
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="dataStream in filteredDataStreams" :key="dataStream.id">
              <th class="shadow-none title lh-1 fw-medium fs-md-15 fs-lg-16 ps-0">
                {{ dataStream.name }}
              </th>
              <td class="shadow-none lh-1 description-text">
                {{ dataStream.description }}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                {{ new Date(dataStream.createdAt).toLocaleString() }}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                {{ new Date(dataStream.updatedAt).toLocaleString() }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-end pe-0">
                <div class="dropdown">
                  <button class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="flaticon-dots"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item d-flex align-items-center" href="#" @click.prevent="openUpdateLightbox(dataStream)">
                        <i class="flaticon-edit lh-1 me-8 position-relative top-1"></i>
                        Edit
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item d-flex align-items-center" href="#" @click.prevent="confirmDelete(dataStream.id)">
                        <i class="flaticon-delete lh-1 me-8 position-relative top-1"></i>
                        Delete
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item d-flex align-items-center" href="#" @click.prevent="navigateToDataTableOverview(dataStream.id)">
                        <i class="flaticon-view lh-1 me-8 position-relative top-1"></i>
                        View
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
            <!-- Placeholder rows to maintain table structure and height -->
            <template v-if="filteredDataStreams.length < 5">
              <tr v-for="i in 5 - filteredDataStreams.length" :key="'placeholder-' + i" class="table-row placeholder-row">
                <td colspan="5">&nbsp;</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="5" class="text-center">No data available</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div class="pagination-area d-md-flex mt-15 mt-md-25 mb-0 justify-content-between align-items-center">
        <p class="mb-0 text-paragraph">
          Showing <span class="fw-bold">{{ filteredDataStreams.length }}</span> out of
          <span class="fw-bold">{{ totalDataStreams }}</span> results
        </p>
        <nav class="mt-15 mt-md-0">
          <ul class="pagination mb-0">
            <li class="page-item" :class="{ disabled: pageIndex === 1 }">
              <a class="page-link" href="#" aria-label="Previous" @click.prevent="changePage(pageIndex - 1)">
                <i class="flaticon-chevron-1"></i>
              </a>
            </li>
            <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: page === pageIndex }">
              <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
            </li>
            <li class="page-item" :class="{ disabled: pageIndex === totalPages }">
              <a class="page-link" href="#" aria-label="Next" @click.prevent="changePage(pageIndex + 1)">
                <i class="flaticon-chevron"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <Lightbox v-if="showCreateLightbox" @close="closeCreateLightbox">
      <CreateDataStream :leadID="leadID" @dataStreamAdded="handleDataStreamAdded" @close="closeCreateLightbox"/>
    </Lightbox>
    <Lightbox v-if="showUpdateLightbox" @close="closeUpdateLightbox">
      <UpdateDataStream :id="selectedDataStream.id" :leadID="leadID" :initialName="selectedDataStream.name" :initialDescription="selectedDataStream.description" @dataStreamUpdated="handleDataStreamUpdated" @close="closeUpdateLightbox"/>
    </Lightbox>
  </div>
</template>

<script>
import { defineComponent, ref, watch, onMounted } from 'vue';
import axios from 'axios';
import Lightbox from '@/components/Common/LightboxWrapper.vue';
import CreateDataStream from '@/components/ELSA/TableManagement/CreateDataStream.vue';
import UpdateDataStream from '@/components/ELSA/TableManagement/UpdateDataStream.vue';
import { useRouter } from 'vue-router';
import { BASE_HSUITE_URL } from '@/core/constant/env';

export default defineComponent({
  name: "DataStreamList",
  components: {
    Lightbox,
    CreateDataStream,
    UpdateDataStream
  },
  props: {
    leadID: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const router = useRouter(); // Initialize the router
    const showCreateLightbox = ref(false);
    const showUpdateLightbox = ref(false);
    const selectedDataStream = ref({});
    const dataStreams = ref([]);
    const searchQuery = ref("");
    const filteredDataStreams = ref([]);
    const pageSize = ref(10);
    const pageIndex = ref(1);
    const totalDataStreams = ref(0);
    const totalPages = ref(1);

    const fetchDataStreams = async (filter = "") => {
      console.log("Fetching data streams for leadID:", props.leadID);  // Debug log
      try {
        const response = await axios.get(`${BASE_HSUITE_URL}/api/DataStream/GetFilteredDataStreams`, {
          params: {
            leadID: props.leadID,
            name: filter || undefined,
            pageSize: pageSize.value,
            pageIndex: pageIndex.value
          }
        });
        console.log("API Response:", response.data);  // Debug log
        if (response.data.status === "Success") {
          dataStreams.value = response.data.dataStreams;
          filteredDataStreams.value = response.data.dataStreams;
          totalDataStreams.value = response.data.totalCount;
          totalPages.value = Math.ceil(totalDataStreams.value / pageSize.value);
        }
      } catch (error) {
        console.error('Error fetching DataStreams:', error);
      }
    };

    const handleSearch = (event) => {
      event.preventDefault();
      const filter = searchQuery.value.trim();
      pageIndex.value = 1; // Reset to first page on search
      fetchDataStreams(filter);
    };

    const changePage = (page) => {
      if (page > 0 && page <= totalPages.value) {
        pageIndex.value = page;
        fetchDataStreams(searchQuery.value);
      }
    };

    const confirmDelete = async (id) => {
      if (confirm('Are you sure you want to delete this data stream? This action cannot be undone.')) {
        try {
          await axios.delete(`${BASE_HSUITE_URL}/api/DataStream/DeleteDataStream/${id}`);
          fetchDataStreams(searchQuery.value); // Refresh the list after deletion
        } catch (error) {
          console.error('Error deleting DataStream:', error);
        }
      }
    };

    const openCreateLightbox = () => {
      showCreateLightbox.value = true;
    };

    const closeCreateLightbox = () => {
      showCreateLightbox.value = false;
    };

    const openUpdateLightbox = (dataStream) => {
      selectedDataStream.value = dataStream;
      showUpdateLightbox.value = true;
    };

    const closeUpdateLightbox = () => {
      showUpdateLightbox.value = false;
    };

    const handleDataStreamAdded = () => {
      closeCreateLightbox();
      fetchDataStreams(searchQuery.value);
    };

    const handleDataStreamUpdated = () => {
      closeUpdateLightbox();
      fetchDataStreams(searchQuery.value);
    };

    const navigateToDataTableOverview = (dataStreamID) => {
      router.push({ name: 'DataTableOverview', params: { dataStreamID } });
    };

    onMounted(() => {
      if (props.leadID) {
        fetchDataStreams();
      }
    });

    watch(() => props.leadID, (newLeadID) => {
      if (newLeadID) {
        fetchDataStreams();
      }
    });

    return {
      showCreateLightbox,
      showUpdateLightbox,
      selectedDataStream,
      dataStreams,
      searchQuery,
      filteredDataStreams,
      pageSize,
      pageIndex,
      totalDataStreams,
      totalPages,
      fetchDataStreams,
      handleSearch,
      changePage,
      confirmDelete,
      openCreateLightbox,
      closeCreateLightbox,
      openUpdateLightbox,
      closeUpdateLightbox,
      handleDataStreamAdded,
      handleDataStreamUpdated,
      navigateToDataTableOverview
    };
  }
});
</script>


<style scoped>
.table-responsive {
  min-height: 300px; /* Ensures table has a minimum height */
}

.table tbody tr.table-row {
  max-height: 50px; /* Ensures each row has a maximum height */
}

.table tbody tr.placeholder-row {
  height: 50px; /* Placeholder rows to maintain table structure and height */
}

.table tbody tr.placeholder-row td {
  background: transparent; /* Makes the placeholder rows transparent */
}

.description-text {
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
