<template>
  <BreadCrumb PageTitle="OOC Analysis Results" />
  <div class="row">
    <div class="col-lg-12">
      <div class="card mb-25 border-0 rounded-0 bg-white sales-pos-location-box">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <OrderLineCountAnalysis :tableName="tableName" />
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="card mb-25 border-0 rounded-0 bg-white sales-pos-location-box">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <OrderCountAnalysis :tableName="tableName" />
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="card mb-25 border-0 rounded-0 bg-white sales-pos-location-box">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <OOCAnalysis_ResultsTable :tableName="tableName" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import BreadCrumb from '../../../components/Common/DynamicBreadCrumb.vue';
import OrderLineCountAnalysis from '../../../components/ELSA/OOCAnalysis/OOCAnalysis_OrderLineChart.vue';
import OrderCountAnalysis from '../../../components/ELSA/OOCAnalysis/OOCAnalysis_Orders.vue';
import OOCAnalysis_ResultsTable from '../../../components/ELSA/OOCAnalysis/OOCAnalysis_ResultsTable.vue';

export default defineComponent({
  name: 'OOCAnalysisResultsPage',
  components: {
    BreadCrumb,
    OrderLineCountAnalysis,
    OrderCountAnalysis,
    OOCAnalysis_ResultsTable,
  },
  setup() {
    const route = useRoute();
    const tableName = ref('');

    onMounted(() => {
      const tableNameParam = route.params.tableName;
      console.log('onMounted: route.params.tableName:', tableNameParam); // Log the route param
      if (typeof tableNameParam === 'string') {
        tableName.value = tableNameParam;
        console.log('onMounted: tableName.value set to:', tableName.value); // Log the tableName after setting it
      } else {
        console.warn('onMounted: tableNameParam is not a string:', tableNameParam); // Warn if tableNameParam is not a string
      }
    });

    watch(
      () => route.params.tableName,
      (newTableName) => {
        console.log('watch: route.params.tableName changed to:', newTableName); // Log when the route param changes
        if (typeof newTableName === 'string') {
          tableName.value = newTableName;
          console.log('watch: tableName.value updated to:', tableName.value); // Log the updated tableName
        } else {
          console.warn('watch: newTableName is not a string:', newTableName); // Warn if newTableName is not a string
        }
      }
    );

    return {
      tableName,
    };
  },
});
</script>

<style scoped>
.card {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
}

.letter-spacing {
  letter-spacing: 0.5px;
}

.h-100 {
  height: 100%;
}
</style>
