export const ElisaEmbedTypeEnum = Object.freeze({
  OrderDataOverview: 0
});

// Reverse mapping to get string from value
export const ElisaEmbedTypeEnumReverse = Object.freeze(
  Object.fromEntries(
    Object.entries(ElisaEmbedTypeEnum).map(([key, value]) => [value, key])
  )
);

// Display name mapping
export const ElisaEmbedTypeDisplayNames = Object.freeze({
  OrderDataOverview: "Order Data Overview"
});

// Function to get enum string
export function getElisaEmbedTypeEnumString(value) {
  const entry = Object.entries(ElisaEmbedTypeEnum).find(([key, val]) => val === value);
  return entry ? entry[0] : "Unknown";
}

// Function to get display name
export function getElisaEmbedTypeDisplayName(value) {
  const entry = Object.entries(ElisaEmbedTypeEnum).find(([key, val]) => val === value);
  if (entry) {
    const [key] = entry;
    return ElisaEmbedTypeDisplayNames[key] || key; // Fallback to key if no display name
  }
  return "Unknown";
}
