<template>
  <div>
    <BreadCrumb PageTitle="Data Overview" />
    <div class="row">
      <div class="col-lg-4">
        <ProjectSummary :leadID="leadID" />
      </div>
      <div class="col-lg-8">
        <DataStreamList :leadID="leadID" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import BreadCrumb from "../../components/Common/DynamicBreadCrumb.vue";
import ProjectSummary from "../../components/Projects/ProjectDetails/ProjectSummary.vue";
import DataStreamList from "../../components/ELSA/TableManagement/DataStreamList.vue";

export default defineComponent({
  name: "LeadDetailsPage",
  components: {
    BreadCrumb,
    ProjectSummary,
    DataStreamList,
  },
  setup() {
    const route = useRoute();
    const leadID = ref<number | null>(null);

    onMounted(() => {
      const id = route.params.leadID;
      if (typeof id === "string") {
        leadID.value = parseInt(id, 10);
        console.log("Lead ID:", leadID.value);  // Debug log
      }
    });

    return {
      leadID,
    };
  },
});
</script>
