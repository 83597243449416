<template>
    <BreadCrumb PageTitle="Projects Layout" />
    <ProjectLayout />
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import ProjectLayout from "../../components/Projects/ProjectLayout/ProjectLayout.vue";
  
  export default defineComponent({
    name: "ProjectsLayoutPage",
    components: {
      BreadCrumb,
      ProjectLayout,
    },
  });
  </script>