<template>
  <div>
    <!-- BreadCrumb Component with pageTitle -->
    <BreadCrumb :PageTitle="pageTitle" />
    
    <!-- EmbedList Component: No need to pass groupKey or enumType anymore -->
    <EmbedList />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRoute } from "vue-router";

// Import your enum and helper functions
import { getElisaEmbedTypeDisplayName } from "@/core/constant/ElisaEmbedTypeEnum";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import EmbedList from "../../components/EmbedPages/EmbedList.vue";

export default defineComponent({
  name: "EmbedPages",
  components: {
    BreadCrumb,
    EmbedList,
  },
  data() {
    return {
      pageTitle: "Embed Pages", // Default pageTitle
    };
  },
  mounted() {
    const route = useRoute();

    // Retrieve groupKey and enumType from the route query
    const groupKey = route.query.groupKey as string | undefined;
    const embedType = route.query.embedType ? parseInt(route.query.embedType as string) : undefined;

    // Convert enumType to its string representation for pageTitle
    const embedTypeString = embedType !== undefined 
      ? getElisaEmbedTypeDisplayName(embedType) 
      : null;

    // Update pageTitle with non-null values
    const titleSuffix = [embedTypeString, groupKey].filter(Boolean).join(" - ");
    if (titleSuffix) {
      this.pageTitle += `: ${titleSuffix}`;
    }
  },
});
</script>
