<template>
  <div>
    <BreadCrumb PageTitle="SKU Data Table List" />
    <SKUDataTableList :enquiryID="enquiryID" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRoute } from 'vue-router';
import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
import SKUDataTableList from "../../../components/ELSA/TableManagement/SKUDataTableList.vue";

export default defineComponent({
  name: "SKUDataTableListPage",
  components: {
    BreadCrumb,
    SKUDataTableList,
  },
  setup() {
    const route = useRoute();
    const enquiryID = route.params.enquiryID;

    return {
      enquiryID
    };
  }
});
</script>
