<template>
  <div class="card mb-25 border-0 rounded-0 bg-white">
    <div class="card-body p-20 p-sm-25 p-md-30 p-lg-35 letter-spacing">
      <div class="d-flex justify-content-between mb-10">
        <span class="fs-16 text-uppercase fw-medium text-dark-emphasis">
          Triple System Analytics Data
        </span>
        <div class="dropdown">
          <button
            class="dropdown-toggle card-dot-btn lh-1 bg-transparent border-0 shadow-none p-0 transition"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="flaticon-dots"></i>
          </button>
          <ul class="dropdown-menu dropdown-menu-end" ref="dropdownMenu">
            <li>
              <a
                class="dropdown-item d-flex align-items-center"
                href="javascript:void(0);"
                @click="exportTable('jpg')"
              >
                <i
                  class="flaticon-download lh-1 me-8 position-relative top-1"
                ></i>
                Export as JPG
              </a>
            </li>
            <li>
              <a
                class="dropdown-item d-flex align-items-center"
                href="javascript:void(0);"
                @click="exportTable('png')"
              >
                <i
                  class="flaticon-download lh-1 me-8 position-relative top-1"
                ></i>
                Export as PNG
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="d-flex justify-content-between mb-3">
        <div class="btn-group" role="group" aria-label="Column Toggle">
          <input
            type="checkbox"
            class="btn-check"
            id="btncheck1"
            autocomplete="off"
            v-model="showTotalScore"
          />
          <label class="btn btn-outline-primary" for="btncheck1">Total Score</label>

          <input
            type="checkbox"
            class="btn-check"
            id="btncheck2"
            autocomplete="off"
            v-model="showNormalisedScore"
          />
          <label class="btn btn-outline-primary" for="btncheck2">Normalised Score</label>

          <input
            type="checkbox"
            class="btn-check"
            id="btncheck3"
            autocomplete="off"
            v-model="showScoreRating"
          />
          <label class="btn btn-outline-primary" for="btncheck3">Score Rating</label>
        </div>
        <div class="dropdown">
          <button
            class="btn btn-outline-primary dropdown-toggle"
            type="button"
            id="pageSizeDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Page Size: {{ itemsPerPage }}
          </button>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="pageSizeDropdown">
            <li><a class="dropdown-item" href="#" @click.prevent="setPageSize(10)">10</a></li>
            <li><a class="dropdown-item" href="#" @click.prevent="setPageSize(25)">25</a></li>
            <li><a class="dropdown-item" href="#" @click.prevent="setPageSize(50)">50</a></li>
            <li><a class="dropdown-item" href="#" @click.prevent="setPageSize(100)">100</a></li>
          </ul>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-striped table-hover" id="tripleSystemAnalyticsTable">
          <thead>
            <tr>
              <th>System 1</th>
              <th>System 2</th>
              <th>System 3</th>
              <th v-if="showTotalScore">Total Score</th>
              <th v-if="showNormalisedScore">Normalised Score</th>
              <th v-if="showScoreRating">Score Rating</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in paginatedData"
              :key="index"
              :class="{ 'table-row-striped': index % 2 === 1 }"
            >
              <td>{{ formatCamelCase(item.System1) }}</td>
              <td>{{ formatCamelCase(item.System2) }}</td>
              <td>{{ formatCamelCase(item.System3) }}</td>
              <td v-if="showTotalScore">{{ item.TotalScore }}</td>
              <td v-if="showNormalisedScore">{{ formatNormalisedScore(item.NormalisedScore) }}</td>
              <td v-if="showScoreRating">
                <div :class="getAlertClass(item.NormalisedScore)" role="alert">
                  {{ getRating(item.NormalisedScore) }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <nav aria-label="Page navigation example" class="mt-3">
        <ul class="pagination">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">Previous</a>
          </li>
          <li
            class="page-item"
            v-for="page in totalPages"
            :key="page"
            :class="{ active: currentPage === page }"
          >
            <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">Next</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue";
import html2canvas from "html2canvas";
import { BASE_HSUITE_URL } from '@/core/constant/env';

export default defineComponent({
  name: "TripleSystemAnalyticsResultsTable",
  props: {
    tableName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const tableData = ref<any[]>([]);
    const dropdownMenu = ref<HTMLElement | null>(null);
    const currentPage = ref(1);
    const itemsPerPage = ref(10);

    const showTotalScore = ref(true);
    const showNormalisedScore = ref(true);
    const showScoreRating = ref(true);

    const totalPages = computed(() => {
      return Math.ceil(tableData.value.length / itemsPerPage.value);
    });

    const paginatedData = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      return tableData.value.slice(start, end);
    });

    const changePage = (page: number) => {
      if (page >= 1 && page <= totalPages.value) {
        currentPage.value = page;
      }
    };

    const setPageSize = (size: number) => {
      itemsPerPage.value = size;
      currentPage.value = 1; // Reset to first page when page size changes
    };

    const getRating = (normalisedScore: number) => {
      if (normalisedScore >= 95) return 'A';
      if (normalisedScore >= 90) return 'B';
      return 'C';
    };

    const getAlertClass = (normalisedScore: number) => {
      if (normalisedScore >= 95) return 'alert alert-success fs-md-15 fs-lg-16';
      if (normalisedScore >= 90) return 'alert alert-warning fs-md-15 fs-lg-16';
      return 'alert alert-danger fs-md-15 fs-lg-16';
    };

    const formatCamelCase = (text: string) => {
      return text.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/^./, function (str) {
        return str.toUpperCase();
      });
    };

    const formatNormalisedScore = (score: number) => {
      return score.toFixed(1);
    };

    const exportTable = async (format: string) => {
      const tableElement = document.getElementById('tripleSystemAnalyticsTable');
      if (!tableElement) return;

      if (dropdownMenu.value) {
        dropdownMenu.value.style.display = 'none';
      }

      // Temporarily set background color to transparent
      const rows = tableElement.querySelectorAll('tr');
      rows.forEach((row) => {
        row.style.backgroundColor = 'transparent';
      });

      const canvas = await html2canvas(tableElement, {
        backgroundColor: format === 'jpg' ? '#FFFFFF' : null,
      });

      // Restore background color after export
      rows.forEach((row, index) => {
        if (index % 2 === 1) {
          row.style.backgroundColor = '#f8f9fa'; // Light grey background for odd rows
        } else {
          row.style.backgroundColor = ''; // Default background for even rows
        }
      });

      if (dropdownMenu.value) {
        dropdownMenu.value.style.display = '';
      }

      const dataURL = canvas.toDataURL(`image/${format}`);
      const link = document.createElement('a');
      link.href = dataURL;
      link.download = `TripleSystemAnalyticsTable.${format}`;
      link.click();
    };

    onMounted(async () => {
      try {
        const response = await fetch(
          `${BASE_HSUITE_URL}/api/elsa/GetTableData/${props.tableName}`
        );
        const data = await response.json();
        tableData.value = data.map((item: any) => ({
          ...item,
          ScoreRating: getRating(item.NormalisedScore),
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });

    return {
      tableData,
      paginatedData,
      currentPage,
      totalPages,
      itemsPerPage,
      showTotalScore,
      showNormalisedScore,
      showScoreRating,
      changePage,
      setPageSize,
      exportTable,
      dropdownMenu,
      getRating,
      getAlertClass,
      formatCamelCase,
      formatNormalisedScore,
    };
  },
});
</script>

<style scoped>
.card-body {
  font-size: 18px; /* Increased font size for all text in card */
}

.table th,
.table td {
  font-size: 18px; /* Increased font size for table text */
}

.table-responsive {
  overflow-x: auto;
}

.dropdown-toggle.card-dot-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.dropdown-menu {
  min-width: 150px;
}

.dropdown-item i {
  margin-right: 10px;
}

.pagination {
  justify-content: center;
}

.page-item.disabled .page-link {
  pointer-events: none;
  opacity: 0.6;
}

.page-item.active .page-link {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}

.btn-group .btn-check:checked + .btn {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}

.alert {
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  text-align: center;
  border-radius: 0.25rem;
}

.table-row-striped:nth-child(odd) {
  background-color: #f8f9fa; /* Light grey background for odd rows */
}
</style>
