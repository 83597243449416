<template>
    <BreadCrumb PageTitle="Teams" />
    <KanbanContent />
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  import BreadCrumb from "@/components/Common/DynamicBreadCrumb.vue";
  import KanbanContent from "@/components/WorkOrderManagement/WorkOrderKanbanBoard.vue";
  
  export default defineComponent({
    name: "KanbanPage",
    components: {
      BreadCrumb,
      KanbanContent,
    },
  });
  </script>