<template>
    <div class="card mb-25 border-0 rounded-0 bg-white">
      <div class="card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing">
        <div class="d-sm-flex align-items-center justify-content-between">
          <h5 class="card-title fw-bold mb-0">Order Data by Container</h5>
        </div>
        <div class="table-responsive">
          <table class="table table-bordered table-hover mt-3">
            <thead>
              <tr>
                <th @click="sortTable('name')">Toggle</th>
                <th @click="sortTable('name')">Container Name</th>
                <th @click="sortTable('count')">Order Count</th>
                <th @click="sortTable('countPercentage')">Order Count (%)</th>
                <th @click="sortTable('orderLineCount')">Order Line Count</th>
                <th @click="sortTable('orderLineCountPercentage')">Order Line Count (%)</th>
                <th @click="sortTable('volume')">Volume (m³)</th>
                <th @click="sortTable('volumePercentage')">Volume (%)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="container in sortedContainers" :key="container.name">
                <td>
                  <input type="checkbox" v-model="container.selected" @change="recalculatePercentages" />
                </td>
                <td>{{ container.name }}</td>
                <td>{{ container.count }}</td>
                <td>{{ container.countPercentage.toFixed(2) }}%</td>
                <td>{{ container.orderLineCount }}</td>
                <td>{{ container.orderLineCountPercentage.toFixed(2) }}%</td>
                <td>{{ container.volume.toFixed(6) }}</td>
                <td>{{ container.volumePercentage.toFixed(2) }}%</td>
              </tr>
              <tr v-if="containers.length === 0">
                <td colspan="8" class="text-center">No data available</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, computed, onMounted, watch } from "vue";
  import axios from "axios";
  import { BASE_HSUITE_URL } from "@/core/constant/env";
  
  interface ContainerData {
    name: string;
    count: number;
    orderLineCount: number;
    volume: number;
    countPercentage: number;
    orderLineCountPercentage: number;
    volumePercentage: number;
    selected: boolean;
  }
  
  export default defineComponent({
    name: "OrderDataTable",
    props: {
      tableName: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const containers = ref<ContainerData[]>([]);
      const sortKey = ref('name');
      const sortOrder = ref('asc');
  
      const fetchData = async () => {
        if (!props.tableName) {
          console.warn("fetchData: tableName is not defined or is empty.");
          return;
        }
  
        try {
          console.log(`fetchData: Fetching data for tableName: ${props.tableName}`);
          const response = await axios.get(`${BASE_HSUITE_URL}/api/OOCAnalysis/GetPrecomputedOrderCounts`, {
            params: {
              tableName: props.tableName,
            },
          });
  
          if (response.data.status === "Success") {
            containers.value = response.data.orderCounts.map((item: any) => ({
              name: item.containerName,
              count: item.count,
              orderLineCount: item.orderLineCount,
              // Convert volume from mm³ to m³
              volume: item.volume / 1e9,
              countPercentage: 0, // Will be calculated later
              orderLineCountPercentage: 0, // Will be calculated later
              volumePercentage: 0, // Will be calculated later
              selected: true, // All containers selected by default
            }));
            recalculatePercentages();
          } else {
            console.warn("fetchData: API response status is not 'Success':", response.data.status);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      const recalculatePercentages = () => {
        const totalCounts = containers.value.reduce(
          (totals, container) => {
            if (container.selected) {
              totals.count += container.count;
              totals.orderLineCount += container.orderLineCount;
              totals.volume += container.volume * 1e9; // Convert back to mm³ for total calculation
            }
            return totals;
          },
          { count: 0, orderLineCount: 0, volume: 0 }
        );
  
        containers.value.forEach((container) => {
          if (container.selected && totalCounts.count > 0) {
            container.countPercentage = (container.count / totalCounts.count) * 100;
          } else {
            container.countPercentage = 0;
          }
  
          if (container.selected && totalCounts.orderLineCount > 0) {
            container.orderLineCountPercentage = (container.orderLineCount / totalCounts.orderLineCount) * 100;
          } else {
            container.orderLineCountPercentage = 0;
          }
  
          if (container.selected && totalCounts.volume > 0) {
            container.volumePercentage = (container.volume * 1e9 / totalCounts.volume) * 100;
          } else {
            container.volumePercentage = 0;
          }
        });
      };
  
      const sortTable = (key: keyof ContainerData) => {
        if (sortKey.value === key) {
          sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
        } else {
          sortKey.value = key;
          sortOrder.value = 'asc';
        }
      };
  
      const sortedContainers = computed(() => {
        return [...containers.value].sort((a, b) => {
          let result = 0;
  
          if (sortKey.value === 'name') {
            result = a.name.localeCompare(b.name);
          } else {
            result = (a[sortKey.value] as number) - (b[sortKey.value] as number);
          }
  
          return sortOrder.value === 'asc' ? result : -result;
        });
      });
  
      onMounted(() => {
        console.log('onMounted: Component mounted, triggering fetchData');
        fetchData();
      });
  
      watch(
        () => props.tableName,
        (newTableName) => {
          if (newTableName) {
            console.log('watch: tableName changed, triggering fetchData for new tableName:', newTableName);
            fetchData();
          }
        }
      );
  
      return {
        containers,
        sortKey,
        sortOrder,
        sortTable,
        sortedContainers,
        recalculatePercentages,
      };
    },
  });
  </script>
  
  <style scoped>
  .table-responsive {
    margin-top: 20px;
  }
  
  .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    border-collapse: collapse;
    cursor: pointer;
  }
  
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }
  
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }
  
  .table tbody + tbody {
    border-top: 2px solid #dee2e6;
  }
  
  .table-hover tbody tr:hover {
    color: #495057;
    background-color: rgba(0, 0, 0, 0.075);
  }
  </style>
  