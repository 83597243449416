<template>
  <BreadCrumb PageTitle="Projects List" />
  <ProjectsTest/>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ProjectsTest from "../../components/Projects/ProjectsList/ProjectTest.vue";

export default defineComponent({
  name: "ProjectsTestPage",
  components: {
    BreadCrumb,
    ProjectsTest,
  },
});
</script>