<template>
  <div class="card mb-25 border-0 rounded-0 bg-white checkout-box letter-spacing">
    <div class="card-body">
      <ul
        class="nav nav-tabs bg-white border-0 rounded-0"
        id="myTab"
        role="tablist"
      >
        <li class="nav-item" role="presentation">
          <button
            ref="emailTab"
            class="nav-link fs-14 fs-md-15 fs-lg-16 fw-semibold position-relative border-0 rounded-0 d-block w-100 active"
            id="shipping-details-tab"
            data-bs-toggle="tab"
            data-bs-target="#shipping-details-tab-pane"
            type="button"
            role="tab"
            aria-controls="shipping-details-tab-pane"
            aria-selected="true"
          >
            <i class="flaticon flaticon-email"></i>
            Email Verification
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link fs-14 fs-md-15 fs-lg-16 fw-semibold position-relative border-0 rounded-0 d-block w-100"
            ref="userProfileTab"
            id="payment-method-tab"
            data-bs-toggle="tab"
            data-bs-target="#payment-method-tab-pane"
            type="button"
            role="tab"
            aria-controls="payment-method-tab-pane"
            aria-selected="false"
            @click="handleUserProfileTabClick"
          >
            <i class="flaticon flaticon-user"></i>
            User Profile
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            ref="registerUserTab"
            class="nav-link fs-14 fs-md-15 fs-lg-16 fw-semibold position-relative border-0 rounded-0 d-block w-100"
            id="place-order-tab"
            data-bs-toggle="tab"
            data-bs-target="#place-order-tab-pane"
            type="button"
            role="tab"
            aria-controls="place-order-tab-pane"
            aria-selected="false"
            @click="handleRegisterUserTabClick"
          >
            <i class="flaticon flaticon-user-2"></i>
            Register User
          </button>
        </li>
      </ul>
      <div class="tab-content p-15 p-sm-20 p-md-25 p-lg-30" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="shipping-details-tab-pane"
          role="tabpanel"
          tabindex="0"
        >
          <h5 class="mb-15 mb-md-25 fw-bold text-black">
            Please Enter the User's Email Address
          </h5>
          <form>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Email Address
                  </label>
                  <input
                    v-model="userEmail"
                    type="email"
                    class="form-control shadow-none rounded-0 text-black"
                    placeholder="e.g. jane3602@gmail.com"
                  />
                </div>
              </div>
            </div>
          </form>
          <div class="d-sm-flex align-items-center justify-content-between">
            <span></span>
            <button
              class="default-btn transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit" @click="goToNextStep('userProfileTab')"
            >
              Save & Next
              <i class="flaticon-right-arrow position-relative ms-5 top-2"></i>
            </button>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="payment-method-tab-pane"
          role="tabpanel"
          tabindex="0"
        >
          <h5 class="mb-15 mb-md-25 fw-bold text-black">
            Please Enter User Profile Details
          </h5>
          <form>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    First Name
                  </label>
                  <input
                    v-model="userProfiles.firstName"
                    type="text"
                    class="form-control shadow-none rounded-0 text-black"
                    placeholder="e.g. Jane"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Last Name
                  </label>
                  <input
                    v-model="userProfiles.lastName"
                    type="text"
                    class="form-control shadow-none rounded-0 text-black"
                    placeholder="e.g. Ronan"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Date of Birth
                  </label>
                  <input
                    v-model="userProfiles.dateOfBirth"
                    type="date"
                    class="form-control shadow-none rounded-0 text-black"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Email Address
                  </label>
                  <input
                    disabled
                    v-model="userEmail"
                    type="email"
                    class="form-control shadow-none rounded-0 text-black"
                    placeholder="e.g. jane3602@gmail.com"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Phone Number
                  </label>
                  <input
                    v-model="userProfiles.phoneNumber"
                    type="text"
                    class="form-control shadow-none rounded-0 text-black"
                    placeholder="e.g. +1-829-3456"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Street
                  </label>
                  <input
                    v-model="userProfiles.street"
                    type="text"
                    class="form-control shadow-none rounded-0 text-black"
                    placeholder="add your location street here"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Suburb
                  </label>
                  <input
                    v-model="userProfiles.suburb"
                    type="text"
                    class="form-control shadow-none rounded-0 text-black"
                    placeholder="e.g. Beverly Hills"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    State
                  </label>
                  <input
                    v-model="userProfiles.state"
                    type="text"
                    class="form-control shadow-none rounded-0 text-black"
                    placeholder="e.g. Florida"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Zip Code
                  </label>
                  <input
                    v-model="userProfiles.postCode"
                    type="text"
                    class="form-control shadow-none rounded-0 text-black"
                    placeholder="enter zip code here"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Country
                  </label>
                  <select v-model="selectedCountry" @change="onCountryChange" class="form-select shadow-none fw-semibold rounded-0">
                    <option v-for="country in countries" :key="country.id" :value="{ id: country.id, name: country.name }">
                       {{ country.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Password
                  </label>
                  <input
                    v-model="userPassword"
                    type="password"
                    class="form-control shadow-none rounded-0 text-black"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Confirm Password
                  </label>
                  <input
                    v-model="confirmPassword"
                    type="password"
                    class="form-control shadow-none rounded-0 text-black"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                    <label class="d-block text-black fw-semibold mb-10">Roles</label>
                    <div v-for="role in this.roles" :key="role.id" class="form-check fs-md-15 fs-lg-16 text-black mb-0 d-inline-block me-15 me-md-25">
                      <input class="form-check-input shadow-none" type="checkbox" :id="role.id"  :value="{ id: role.id, name: role.name }"   v-model="selectedRoles" />
                      <label class="form-check-label"> {{ role.name }} </label>
                    </div>
                  </div>
              </div>
            </div>
          </form>
          <div
            class="mt-15 mt-sm-20 mt-md-25 d-sm-flex align-items-center justify-content-between"
          >
            <button
              @click="goToPreviousStep('emailTab')"
              type="button"
              class="d-inline-block fs-14 fs-md-15 fs-lg-16 fw-medium text-decoration-none bg-transparent p-0 border-0 text-primary mb-12 mb-sm-0"
            >
              <i
                class="flaticon-left-arrow lh-1 me-5 position-relative top-2"  
              ></i>
              Return To Email Verification
            </button>
            <button
              class="default-btn transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit" @click="goToNextStep('registerUserTab')"
            >
              Save & Next
              <i class="flaticon-right-arrow position-relative ms-5 top-2"></i>
            </button>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="place-order-tab-pane"
          role="tabpanel"
          tabindex="0"
        >
          <h5 class="mb-15 mb-md-25 fw-bold text-black">
            Please Verify the User Information You Have Entered
          </h5>
          <div class="box p-15 p-sm-20 p-md-25 mb-15 mb-md-25">
            <div
              class="mb-15 mb-sm-20 mb-md-25 d-flex align-items-center justify-content-between"
            >
              <h6 class="fw-bold text-black-emphasis mb-0">User Profile</h6>
              <a
                @click="goToPreviousStep('userProfileTab')"
                href="#"
                class="d-inline-block text-decoration-none lh-1 text-muted fw-medium"
                ><i
                  class="ph-duotone ph-pencil text-black position-relative top-1 fs-16 me-1"
                ></i>
                Edit</a
              >
            </div>
            <div class="inner-box p-15 p-sm-20 p-md-25 bg-white">
              <table class="fs-md-15 fs-lg-16">
                <tbody>
                  <tr>
                    <td class="text-paragraph" style="width: auto; white-space: nowrap;">First Name</td>
                    <td class="colon" style="width: 1px; white-space: nowrap;">:&nbsp;&nbsp;&nbsp;</td>
                    <td>{{userProfiles.firstName}}</td>
                  </tr>
                  <tr>
                    <td class="text-paragraph" style="width: auto; white-space: nowrap;">Last Name</td>
                    <td class="colon" style="width: 1px; white-space: nowrap;">: </td>
                    <td>{{userProfiles.lastName}}</td>
                  </tr>
                  <tr>
                    <td class="text-paragraph" style="width: auto; white-space: nowrap;">Date of Birth</td>
                    <td class="colon" style="width: 1px; white-space: nowrap;">: </td>
                    <td>{{userProfiles.dateOfBirth}}</td>
                  </tr>
                  <tr>
                    <td class="text-paragraph" style="width: auto; white-space: nowrap;">Email</td>
                    <td class="colon" style="width: 1px; white-space: nowrap;">: </td>
                    <td>{{userProfiles.userEmail}}</td>
                  </tr>
                  <tr>
                    <td class="text-paragraph" style="width: auto; white-space: nowrap;">Phone</td>
                    <td class="colon" style="width: 1px; white-space: nowrap;">: </td>
                    <td>{{userProfiles.phoneNumber}}</td>
                  </tr>
                  <tr>
                    <td class="text-paragraph" style="width: auto; white-space: nowrap;">Street</td>
                    <td class="colon" style="width: 1px; white-space: nowrap;">: </td>
                    <td>{{userProfiles.street}}</td>
                  </tr>
                  <tr>
                    <td class="text-paragraph" style="width: auto; white-space: nowrap;">Suburb</td>
                    <td class="colon" style="width: 1px; white-space: nowrap;">: </td>
                    <td>{{userProfiles.suburb}}</td>
                  </tr>
                  <tr>
                    <td class="text-paragraph" style="width: auto; white-space: nowrap;">State</td>
                    <td class="colon" style="width: 1px; white-space: nowrap;">: </td>
                    <td>{{userProfiles.state}}</td>
                  </tr>
                  <tr>
                    <td class="text-paragraph" style="width: auto; white-space: nowrap;">Zip Code</td>
                    <td class="colon" style="width: 1px; white-space: nowrap;">: </td>
                    <td>{{userProfiles.postCode}}</td>
                  </tr>
                  <tr>
                    <td class="text-paragraph" style="width: auto; white-space: nowrap;">Country</td>
                    <td class="colon" style="width: 1px; white-space: nowrap;">: </td>
                    <td>{{selectedCountry.name}}</td>
                  </tr>
                </tbody>
              </table>
          </div>
        </div>
          <div class="box p-15 p-sm-20 p-md-25">
            <div
              class="mb-15 mb-sm-20 mb-md-25 d-flex align-items-center justify-content-between"
            >
              <h6 class="fw-bold text-black-emphasis mb-0">Roles</h6>
              <a
                @click="goToPreviousStep('userProfileTab')"
                href="#"
                class="d-inline-block text-decoration-none lh-1 text-muted fw-medium"
              >
                <i
                  class="ph-duotone ph-pencil text-black position-relative top-1 fs-16 me-1"
                >
                </i>
                Edit
              </a>
            </div>
            <div class="d-flex align-items-center payment-method">
              <div v-for="role in selectedRoles" :key="role.id" class="form-check fs-md-15 fs-lg-16 text-black mb-0 d-inline-block me-15 me-md-25">
                  <input class="form-check-input shadow-none" type="checkbox" checked disabled />
                  <span class="d-block fw-semibold text-black">
                    {{ role.name }}
                  </span>
              </div>
            </div>
          </div>
          <div
            class="mt-15 mt-sm-20 mt-md-25 d-sm-flex align-items-center justify-content-between"
          >
            <button
              @click="goToPreviousStep('userProfileTab')"
              type="button"
              class="d-inline-block fs-14 fs-md-15 fs-lg-16 fw-medium text-decoration-none bg-transparent p-0 border-0 text-primary mb-8 mb-sm-0"
            >
              <i
                class="flaticon-left-arrow lh-1 me-5 position-relative top-2"
              ></i>
              Return To User Profile
            </button>
            <button
              @click="saveData()"
              class="default-btn transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
              Register
              <i class="flaticon-right-arrow position-relative ms-5 top-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { ref } from 'vue';
import { getAllRoles, findUserProfile, registerUser, findUserByEmail} from '@/core/services/entities/authentication.service'
import { onMounted } from 'vue';
import { useToast } from 'vue-toastification'
import { useRouter } from "vue-router";
import HSuiteApiService from '@/core/services/hsuiteApi.service'

export default {
  name: "AddUserWizard",
  setup() {
    const emailTab = ref(null);
    const userProfileTab = ref(null);
    const registerUserTab = ref(null);
    const userEmail=ref("");
    const userPassword=ref("");
    const confirmPassword=ref("");
    const roles=ref([]);
    const selectedRoles= ref([]); 
    const router = useRouter();
    const toast = useToast();
    const countries=ref([]);
    const passwordError = ref('');
    const selectedCountry=ref({
        id: null,
        name: ''
      });

    const userProfiles = ref({
      userId: '',
      firstName: '', 
      lastName: '',
      countryId: null,
      state: '',
      suburb: '',
      street: '',
      userEmail:'',
      postCode: '',
      phoneNumber: '',
      dateOfBirth: ''
    });

    const onCountryChange = async()=> {
      userProfiles.value.countryId=selectedCountry.value.id;
    };

    const fetchCountries = async()=> {
      var countrySearch='';
      var pageSize=239;
      var pageIndex=1;

      try {
        const response = await HSuiteApiService.get('/api/master/GetCountriesByFilter', `${countrySearch}?pageSize=${pageSize}&pageIndex=${pageIndex}`);
        countries.value = response.data.countries;
      } catch (error) {
        console.error(error);
      }
    };

    const goToMainMenu = async () => {
      router.push({ name: "UsersListPage" });
    };

    const handleUserProfileTabClick=(event)=> {
      if (!isEmailValid(userEmail.value)) {
        event.preventDefault();
        toast.error('Email is required', {
            timeout: 2000,
            });
        emailTab.value.click();    
      }else{
        getUserProfiles();
        userProfiles.value.userEmail=userEmail.value;
      }
    };

    const handleRegisterUserTabClick=(event)=> {
      if (!isEmailValid(userEmail.value)) {
        event.preventDefault();
        toast.error('Email is required', {
            timeout: 2000,
            });
        emailTab.value.click();    
      }else{
        if(!userProfiles.value.userEmail){
          event.preventDefault();
          emailTab.value.click();   
        }else{
          if(!validate()){
            event.preventDefault();
            userProfileTab.value.click();     
          }
        }
      }
    };

    const isEmailValid=(email)=> {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const showMessage=(message,messageType)=>{
      if(messageType=='Error'){
        toast.error(message, {
          timeout: 2000,
        });
      }else{
        toast.success(message, {
          timeout: 2000,
        });
      }
    };

    const validate = () => {
      const requiredFields = {
        firstName: 'First Name is required',
        lastName: 'Last Name is required',
        userEmail:'Emai is required',
      };

      for (const field in requiredFields) {
        if (!userProfiles.value[field]) {
          showMessage(requiredFields[field],'Error');
          return false;
        }
      }

      if(!userPassword.value){
        toast.error('User password is required.', {
          timeout: 2000,
        });
        return false; 
      }

      if(userPassword.value!=confirmPassword.value){
        toast.error('Passwords do not match. Please try again.', {
          timeout: 2000,
        });
        return false; 
      }

      if(!validatePassword()){
        return false;
      }

      return true; 
    };

    const goToNextStep = async (nextTab) => {
      userProfiles.value.userEmail=userEmail.value;
      if (nextTab === 'userProfileTab') {
        if(isEmailValid(userEmail.value)){
          const isHasBeenRegistered = ref(false);
          isHasBeenRegistered.value = await isRegistered();
          if (!isHasBeenRegistered.value) {
            getUserProfiles();
            userProfileTab.value.click();
          } else {
            toast.error('User has been registered.', {
              timeout: 2000,
            });
          }
        }else{
          toast.error('Email is required', {
            timeout: 2000,
            });
        }
      } else if (nextTab === 'registerUserTab') {
        if(validate()){
          registerUserTab.value.click();
        }
      }
    };

    const goToPreviousStep = (previousTab) => {
      if (previousTab === 'emailTab') {
        emailTab.value.click();
      } else if (previousTab === 'userProfileTab') {
        userProfileTab.value.click();
      }
    };

    const getUserProfiles = async () => {
      try {
        let temp = await findUserProfile(userEmail.value); 
        if (temp) {
          userProfiles.value=temp;
        }else{
          userProfiles.value.userId='-';
        }
      } catch (error) {
        console.error("Error fetching user profiles:", error);
      }
    };

    const isRegistered = async () => {
      try {
        let temp = await findUserByEmail(userEmail.value); 
        if (temp) {
          return true;
        }
        return false;
      }catch(error) {
        console.error(error);
      }
    };

    const initRoles = async () =>{
      roles.value = await getAllRoles();
    };

    const saveData = async () => {
      const roleIds = selectedRoles.value.map(role => role.id);
      
      if (!userProfiles.value.dateOfBirth) {
        userProfiles.value.dateOfBirth = null;
      }

      const payload = {
        user: {userName:userEmail.value,password:userPassword.value},
        userProfile: userProfiles.value,
        roleIds: roleIds
      };

      try{
        var responesRegisterUser=await registerUser(payload);
        if(responesRegisterUser.status == 200 || responesRegisterUser.status == 201)
        {
          toast.success('User has been successfully registered.', {
            timeout: 2000,
          });
          goToMainMenu();  
        }else{
          toast.error('Failed to register user', {
            timeout: 2000,
          });
        }
      }catch(error){
        toast.error(error.response.data, {
          timeout: 5000,
        });
      } 
    };

    const validatePassword = () => {
      const minLength = 8;
      const passwordRegex = {
        length: new RegExp(`^.{${minLength},}$`),
        uppercase: /[A-Z]/,
        lowercase: /[a-z]/,
        number: /\d/,
        specialChar: /[!@#$%^&*(),.?":{}|<>]/
      };

      passwordError.value = [];

      if (!passwordRegex.length.test(userPassword.value)) {
        passwordError.value.push(`Password must be at least ${minLength} characters long.`);
      }

      if (!passwordRegex.uppercase.test(userPassword.value)) {
        passwordError.value.push('Password must contain at least one uppercase letter.');
      }

      if (!passwordRegex.lowercase.test(userPassword.value)) {
        passwordError.value.push('Password must contain at least one lowercase letter.');
      }

      if (!passwordRegex.number.test(userPassword.value)) {
        passwordError.value.push('Password must contain at least one number.');
      }

      if (!passwordRegex.specialChar.test(userPassword.value)) {
        passwordError.value.push('Password must contain at least one special character.');
      }

      if (passwordError.value.length === 0) {
        return true;
      } else {
        const errorMessage = passwordError.value.map((error, index) => `${index + 1}. ${error}`).join('\n');
        toast.error(errorMessage, {
          timeout: 4000,
        });
        return false;
      }
    };

    onMounted(() => {
      initRoles();
      fetchCountries();
    });

    return {
      emailTab,
      userProfileTab,
      goToNextStep,
      goToPreviousStep,
      userEmail,
      userProfiles,
      roles,
      selectedRoles,
      saveData,
      goToMainMenu,
      handleUserProfileTabClick,
      fetchCountries,
      countries,
      selectedCountry,
      handleRegisterUserTabClick,
      registerUserTab,
      onCountryChange,
      isRegistered,
      userPassword,
      confirmPassword,
      validatePassword
    };
  }
};

</script>