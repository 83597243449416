<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative" @submit.prevent="handleSearch">
          <input type="text" class="form-control shadow-none text-black rounded-0 border-0" placeholder="Search SKU Data"
            v-model="searchQuery" />
          <button type="submit" class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <select class="form-select shadow-none fw-semibold rounded-1 mt-10 mt-sm-0 ms-sm-10" v-model="pageSize" @change="fetchSKUData" style="width: 150px;">
          <option :value="5">5</option>
          <option :value="10">10</option>
          <option :value="25">25</option>
          <option :value="50">50</option>
        </select>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="d-flex justify-content-between mb-3">
        <select class="form-select shadow-none fw-semibold rounded-1" v-model="selectedStatus" style="width: 200px;">
          <option value="">Any</option>
          <option value="RAW">RAW</option>
          <option value="WIP">WIP</option>
          <option value="CLEAN">CLEAN</option>
          <option value="MODDEV">MODDEV</option>
          <option value="BASE">BASE</option>
          <option value="SCEN">SCEN</option>
        </select>
        <div class="pagination-area d-md-flex justify-content-end align-items-center">
          <nav>
            <ul class="pagination mb-0">
              <li class="page-item" :class="{ disabled: pageIndex === 1 }">
                <a class="page-link" href="#" aria-label="Previous" @click.prevent="changePage(pageIndex - 1)">
                  <i class="flaticon-chevron-1"></i>
                </a>
              </li>
              <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: page === pageIndex }">
                <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
              </li>
              <li class="page-item" :class="{ disabled: pageIndex === totalPages }">
                <a class="page-link" href="#" aria-label="Next" @click.prevent="changePage(pageIndex + 1)">
                  <i class="flaticon-chevron"></i>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0" style="min-height: 300px;">
          <thead>
            <tr>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
                ENQUIRY ID
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                STATUS
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                TABLE NAME
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                TABLE ROWS
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                CREATE TIME
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                UPDATE TIME
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0">
                ACTIONS
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="filteredSKUData.length">
              <tr v-for="sku in filteredSKUData" :key="sku.TABLE_NAME" class="table-row">
                <td class="shadow-none title lh-1 fw-medium fs-md-15 fs-lg-16 ps-0">
                  {{ sku.EnquiryID }}
                </td>
                <td class="shadow-none lh-1">
                  {{ sku.Status }}
                </td>
                <td class="shadow-none lh-1 fw-medium">
                  {{ sku.TABLE_NAME }}
                </td>
                <td class="shadow-none lh-1 fw-medium">
                  {{ sku.TABLE_ROWS }}
                </td>
                <td class="shadow-none lh-1 fw-medium">
                  {{ sku.CREATE_TIME }}
                </td>
                <td class="shadow-none lh-1 fw-medium">
                  {{ sku.UPDATE_TIME }}
                </td>
                <td class="shadow-none lh-1 fw-medium text-end pe-0">
                  <div class="dropdown">
                    <button class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition" type="button"
                      data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="flaticon-dots"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <router-link :to="{ name: 'SKUDataTablePage', params: { tableName: sku.TABLE_NAME } }" class="dropdown-item d-flex align-items-center">
                          <i class="flaticon-view lh-1 me-8 position-relative top-1"></i>
                          View Data
                        </router-link>
                      </li>
                      <li>
                        <router-link :to="{ name: 'SuggestedSystemAnalysisPage', params: { tableName: sku.TABLE_NAME } }" class="dropdown-item d-flex align-items-center">
                          <i class="flaticon-chart lh-1 me-8 position-relative top-1"></i>
                          Suggested System Analysis
                        </router-link>
                      </li>
                      <li>
                        <router-link :to="{ name: 'TripleSystemAnalysisPage', params: { tableName: sku.TABLE_NAME.replace('SKUData', 'RESULTSTriSys') } }" class="dropdown-item d-flex align-items-center">
                          <i class="flaticon-layer lh-1 me-8 position-relative top-1"></i>
                          Triple System Analysis
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </template>
            <!-- Placeholder rows to maintain table structure and height -->
            <template v-if="filteredSKUData.length < 5">
              <tr v-for="i in 5 - filteredSKUData.length" :key="'placeholder-' + i" class="table-row placeholder-row">
                <td colspan="7">&nbsp;</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="7" class="text-center">No data available</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div class="pagination-area d-md-flex mt-15 mt-md-25 mb-0 justify-content-between align-items-center">
        <p class="mb-0 text-paragraph">
          Showing <span class="fw-bold">{{ filteredSKUData.length }}</span> out of
          <span class="fw-bold">{{ totalSKUData }}</span> results
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { BASE_HSUITE_URL } from '@/core/constant/env';
export default {
  name: "SKUDataTableList",
  props: {
    enquiryID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      skuData: [],
      searchQuery: "",
      selectedStatus: "", // Data property for selected status
      pageSize: 10,
      pageIndex: 1,
      totalSKUData: 0,
      totalPages: 1
    };
  },
  computed: {
    filteredSKUData() {
      let filtered = this.skuData;
      if (this.selectedStatus) {
        filtered = filtered.filter(sku => sku.Status === this.selectedStatus);
      }
      if (this.searchQuery) {
        const searchQueryLower = this.searchQuery.toLowerCase();
        filtered = filtered.filter(sku => sku.TABLE_NAME.toLowerCase().includes(searchQueryLower));
      }
      return filtered;
    }
  },
  methods: {
    async fetchSKUData() {
      try {
        const response = await fetch(`${BASE_HSUITE_URL}/api/ELSA/GetSKUDataTables/${this.enquiryID}`);
        const data = await response.json();
        console.log(data);
        if (data) {
          this.skuData = data;
          this.totalSKUData = data.length;
          this.totalPages = Math.ceil(this.totalSKUData / this.pageSize);
        }
      } catch (error) {
        console.error('Error fetching SKU data:', error);
      }
    },
    handleSearch(event) {
      event.preventDefault();
      this.pageIndex = 1;
    },
    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.pageIndex = page;
        this.fetchSKUData();
      }
    }
  },
  watch: {
    enquiryID: {
      immediate: true,
      handler() {
        this.fetchSKUData();
      }
    }
  },
  created() {
    this.fetchSKUData();
  }
};
</script>

<style scoped>
.pagination-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination-area select {
  width: 200px;
}

.table-responsive {
  min-height: 300px; /* Ensures table has a minimum height */
}

.table tbody tr.table-row {
  max-height: 50px; /* Ensures each row has a maximum height */
}

.table tbody tr.placeholder-row {
  height: 50px; /* Placeholder rows to maintain table structure and height */
}

.table tbody tr.placeholder-row td {
  background: transparent; /* Makes the placeholder rows transparent */
}

/* Styles for dropdown submenu */
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
}
</style>
