import store from '@/store/index.js'
import { LOGIN, LOGOUT, GET_LAYOUTS } from '@/store/auth.module'
import HAuthApiService from '@/core/services/hauthApi.service'
import HSuiteApiService from '@/core/services/hsuiteApi.service'

async function Login({ username, password, isRememberMe }) {
  return store.dispatch(LOGIN, { username, password, isRememberMe })
}

function GetLayouts() {
  return store.dispatch(GET_LAYOUTS)
}

function Logout() {
  store.dispatch(LOGOUT)
}

function Validate() {
  return HAuthApiService.get('/api/oauth/validate')
}

function DefaultDateFormat() {
  return HAuthApiService.get('/api/oauth/default-date-format')
}

async function UpdateUserLayout(params) {
  await HSuiteApiService.post('/api/ElisaAuth/UpdateUserLayout', params).then(response => {
    if (response.data) {
      return response.data
    }
  })
  .catch(ex => {
    console.log(ex)
    return null
  })
}

async function GetUserLayout(id) {
  let result = null;
  await HSuiteApiService.get('/api/ElisaAuth/GetUserLayout/'+id).then(response => {
    result = response.data.userLayout;
  })
  .catch(ex => {
    console.log(ex);
  })

  return result;
}

async function GetUsersByFilter(queryFilter) {
  let result = null;
  await HSuiteApiService.get('/api/ElisaAuth/GetUsersByFilter',queryFilter).then(response => {
    result = response.data;
  })
  .catch(ex => {
    console.log(ex);
  })

  return result;
}

async function GetAllRoles() {
  let result = null;
  await HSuiteApiService.get('/api/ElisaAuth/GetAllRoles').then(response => {
    result = response.data;
  })
  .catch(ex => {
    console.log(ex);
  })

  return result;
}

async function FindUserWithRolesById(userId) {
  let result = null;
  await HSuiteApiService.get(`/api/ElisaAuth/FindUserWithRolesById/${userId}`).then(response => {
    result = response.data;
  })
  .catch(ex => {
    console.log(ex);
  })

  return result;
}

async function AssignRolesToUser(data) {
  let result = null;
  await HSuiteApiService.post(`/api/ElisaAuth/AssignRolesToUser`, data).then(response => {
    result = response;
    console.log(response)
  })
  .catch(ex => {
    console.log(ex);
  })

  return result;
}

async function GetRolesByFilter(filter="",pageSize,pageIndex) {
  let result = null;
  await HSuiteApiService.get(`/api/ElisaAuth/GetRolesByFilter`,`?filter=${filter}&pageSize=${pageSize}&pageIndex=${pageIndex}`).then(response => {
    result = response.data;
  })
  .catch(ex => {
    console.log(ex);
  })

  return result;
}

async function DeleteRole(payLoad) {
  let result = null;
  await HSuiteApiService.post(`/api/ElisaAuth/DeleteRole`, payLoad).then(response => {
    result = response;
    console.log(response)
  })
  .catch(ex => {
    console.log(ex);
    throw ex;
  })

  return result;
}

async function UpdateRole(data) {
  let result = null;
  await HSuiteApiService.post(`/api/ElisaAuth/UpdateRole`, data).then(response => {
    result = response;
    console.log(response)
  })
  .catch(ex => {
    console.log(ex);
    throw ex;
  })

  return result;
}

async function findUserProfile(email) {
  let result = null;
  await HSuiteApiService.get(`/api/ElisaAuth/FindUserProfileByEmail/${email}`).then(response => {
    result = response.data;
  })
  .catch(ex => {
    console.log(ex);
  })

  return result;
}

async function registerUser(data) {
  let result = null;
  await HSuiteApiService.post(`/api/ElisaAuth/register`, data).then(response => {
    result = response;
    console.log(response)
  })
  .catch(ex => {
    console.log(ex);
    throw ex;
  })

  return result;
}

async function AddUserProfile(data) {
  let result = null;
  await HSuiteApiService.post(`/api/ElisaAuth/AddUserProfile`, data).then(response => {
    result = response;
    console.log(response)
  })
  .catch(ex => {
    console.log(ex);
    throw ex;
  })

  return result;
}

async function UpdateUserProfile(data) {
  let result = null;
  await HSuiteApiService.post(`/api/ElisaAuth/UpdateUserProfile`, data).then(response => {
    result = response;
    console.log(response)
  })
  .catch(ex => {
    console.log(ex);
    throw ex;
  })

  return result;
}

async function findUserByEmail(email) {
  let result = null;
  await HSuiteApiService.get(`/api/ElisaAuth/FindUserByEmail/${email}`).then(response => {
    result = response.data;
  })
  .catch(ex => {
    console.log(ex);
  })

  return result;
}

async function ResetPassword(data) {
  let result = null;
  await HSuiteApiService.post(`/api/ElisaAuth/ResetPassword`, data).then(response => {
    result = response;
    console.log(response)
  })
  .catch(ex => {
    console.log(ex);
    throw ex;
  })

  return result;
}

export { Login, Logout, Validate, DefaultDateFormat, GetLayouts, 
  UpdateUserLayout, GetUserLayout, GetUsersByFilter, GetAllRoles, 
  FindUserWithRolesById, AssignRolesToUser,GetRolesByFilter,DeleteRole,
  UpdateRole,findUserProfile,registerUser,AddUserProfile,UpdateUserProfile,
  findUserByEmail,ResetPassword }
