import HAuthApiService from '@/core/services/hauthApi.service'
import HSuiteApiService from '@/core/services/hsuiteApi.service'
import JwtService from '@/core/services/jwt.service'
import router from '@/router/index'

// action types
export const VERIFY_AUTH = 'verifyAuth'
export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const REGISTER = 'register'
export const UPDATE_PASSWORD = 'updateUser'
export const ACTIVATE_ACCOUNT = 'activateAccount'
export const FORGOT_PASSWORD = 'forgotPassword'
export const CHECK_AUTH = 'checkAuth'
export const CHANGE_SHOW_ACTION_AS_ICON_STATE = 'changeShowActionAsIconState'
export const GET_LAYOUTS = 'getLayouts'
export const SET_LAYOUTS = 'setLayouts'
export const GET_USER_PERMISSIONS ='getUserPermissions'
export const SET_USER_PERMISSIONS ='setUserPermissions'
export const GET_ELISA_USER = 'getElisaUser'
export const SET_ELISA_USER = 'setElisaUser'

// mutation types
export const PURGE_AUTH = 'logOut'
export const SET_AUTH = 'setUser'
export const SET_PASSWORD = 'setPassword'
export const SET_ERROR = 'setError'
export const SET_SETTING_IS_SHOW_ACTION_AS_ICON = 'SetSettingIsShowActionAsIcon'

const state = {
  errors: null,
  user: {},
  projects: [],
  isAuthenticated: !!JwtService.getToken(),
  layouts: [],
}

const getters = {
  userId(state) {
    return parseInt(state.user.id)
  },
  currentUser(state) {
    return state.user
  },
  currentRole(state) {
    return state.user.roleEnum
  },
  currentRoles(state) {
    return state.user.roleList
  },
  currentPermissions(state) {
    return state.user.permissionList
  },
  currentProjects(state) {
    return state.projects
  },
  isAuthenticated(state) {
    return state.isAuthenticated
  },
  allowView(state, getters) {
    const meta = router.currentRoute._value.meta
    return getters.currentPermissions?.includes(meta.permission)
  },
  allowUpload(state, getters) {
    const meta = router.currentRoute._value.meta
    return getters.currentPermissions.includes(meta.allowUploadPermission)
  },
  allowAdd(state, getters) {
    const meta = router.currentRoute._value.meta
    return getters.currentPermissions?.includes(meta.allowAddPermission)
  },
  allowEdit(state, getters) {
    const meta = router.currentRoute._value.meta
    return getters.currentPermissions.includes(meta.allowEditPermission)
  },
  allowDelete(state, getters) {
    const meta = router.currentRoute._value.meta
    return getters.currentPermissions.includes(meta.allowDeletePermission)
  },
  allowPrint(state, getters) {
    const meta = router.currentRoute._value.meta
    return getters.currentPermissions.includes(meta.allowPrintPermission)
  },
  currentLayouts(state) {
    return state.layouts
  },
  allowResetPassword(state, getters) {
    const meta = router.currentRoute._value.meta
    return getters.currentPermissions?.includes(meta.allowResetPasswordPermission)
  },
}

const actions = {
  [CHECK_AUTH]({ commit }) {
    return new Promise(resolve => {
      HAuthApiService.get('/api/oauth/validate')
        .then(response => {
          resolve(response)
        })
        .catch(ex => {
          commit(SET_ERROR, ex)
        })
    })
  },
  [LOGIN]({ commit,dispatch }, credentials) {
    return new Promise(resolve => {
      HAuthApiService.post('/api/Auth/login', credentials)
        .then(response => {
          if (response.data.isSuccess) {
            commit(SET_AUTH, response.data);
            console.log(response.data);
            dispatch(GET_USER_PERMISSIONS).then(() => {
              dispatch(GET_ELISA_USER).then(() => {
                resolve(response.data);
              }).catch(ex => {
                commit(SET_ERROR, ex);
                resolve({ message: `${ex.response.data}`, isSuccess: false });
              });
            }).catch(ex => {
              commit(SET_ERROR, ex);
              resolve({ message: `${ex.response.data}`, isSuccess: false });
            });
          } else {
            commit(SET_ERROR, response.data.message);
            resolve(response.data);
          }
        })
        .catch(ex => {
          commit(SET_ERROR, ex)
          resolve({message: `${ex.response.data}`, isSuccess: false})
        })
    })
  },
  [ACTIVATE_ACCOUNT](context, credentials) {
    return new Promise(resolve => {
      HAuthApiService.post('api/oauth/activate-account', credentials)
        .then(({ data }) => {
          if (data.isSuccess) {
            context.commit(SET_AUTH, data)
            resolve(data)
          } else {
            context.commit(SET_ERROR, data.message)
            resolve(null)
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },
  [SET_AUTH](context, data) {
    context.commit(SET_AUTH, data)
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH)
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      HAuthApiService.post('api/users', credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data)
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },
  [FORGOT_PASSWORD](context, credentials) {
    return new Promise(resolve => {
      HAuthApiService.post('api/oauth/forgot-password', credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data)
          resolve(data)
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    })
  },
  [VERIFY_AUTH]({ commit }) {
    if (JwtService.getToken()) {
      commit(SET_AUTH, JwtService.getUser())
      commit(SET_LAYOUTS, JwtService.getLayouts())
      commit(SET_USER_PERMISSIONS, JwtService.getPermissions())
      commit(SET_ELISA_USER, JwtService.getElisaUser())
    } else {
      commit(PURGE_AUTH)
    }
  },
  [PURGE_AUTH]({ commit }) {
    commit(PURGE_AUTH)
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload

    return HAuthApiService.put('password', password).then(({ data }) => {
      context.commit(SET_PASSWORD, data)
      return data
    })
  },
  [CHANGE_SHOW_ACTION_AS_ICON_STATE](context, payload) {
    return HAuthApiService.post('api/Users/toggle-action-as-icon', payload).then(({ data }) => {
      context.commit(SET_SETTING_IS_SHOW_ACTION_AS_ICON, payload.settingIsShowActionAsIcon)
      return data
    })
  },
  [GET_LAYOUTS]({ commit }) {
    return new Promise(resolve => {
      HSuiteApiService.get('/api/ElisaAuth/GetAllAuthLayouts')
        .then(response => {
          if (response.data.status == "Success") {
            commit(SET_LAYOUTS, response.data.userLayouts)
            resolve(response.data.userLayouts)
          }
        })
        .catch(ex => {
          commit(SET_ERROR, ex)
        })
    })
  },
  [SET_LAYOUTS](context, data) {
    context.commit(SET_LAYOUTS, data)
  },
  [GET_USER_PERMISSIONS]({ commit, state }) {
    return HSuiteApiService.get('/api/ElisaAuth/GetUserPermissionsByUserEmail/' + state.user.email)
      .then(response => {
        if (response.status === 200) {
          commit(SET_USER_PERMISSIONS, response.data); 
        }
      })
      .catch(ex => {
        commit(SET_ERROR, ex);
      });
  },
  [GET_ELISA_USER]({ commit, state }) {
    return HSuiteApiService.get('/api/ElisaAuth/FindUserByEmail', state.user.email)
    .then(response => {
      if (response.status === 200) {
        commit(SET_ELISA_USER, response.data)
      }
    })
    .catch(ex => {
      commit(SET_ERROR, ex);
    });
  }
}

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true
    state.user = user
    state.errors = {}
    state.projects = ['Rumah', 'lna1', 'Tangguh']

    window.axios.interceptors.request.use(function(config) {
      config.headers.Authorization = `Bearer ${state.user.token}`
      return config
    })

    const refreshAndRetryQueue = [];
    let isRefreshing = false;

    window.axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalConfig = error.config;

        if (window.location.pathname === '/login') {
          return Promise.reject(error);
        }

        if (error.response && error.response.status === 401) {
          if (originalConfig._retry) {
            return Promise.reject(error);
          }
          originalConfig._retry = true;

          if (!isRefreshing) {
            isRefreshing = true;

            return HAuthApiService.post("/api/Auth/refresh-token", {
              refreshToken: state.user.refreshToken,
              validateStatus: () => true
            })
              .then((refreshResponse) => {
                
                const { accessToken, refreshToken } = refreshResponse.data;

                state.user.token = accessToken;
                state.user.refreshToken = refreshToken;
                JwtService.saveUser(state.user);

                originalConfig.headers.Authorization = `Bearer ${accessToken}`;

                refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
                  config.headers.Authorization = `Bearer ${accessToken}`;
                  window.axios
                    .request(config)
                    .then((res) => resolve(res))
                    .catch((err) => reject(err));
                });

                refreshAndRetryQueue.length = 0;
                isRefreshing = false;

                return window.axios(originalConfig);
              })
              .catch((refreshError) => {
                isRefreshing = false;

                refreshAndRetryQueue.forEach(({ reject }) => reject(refreshError));
                refreshAndRetryQueue.length = 0;

                state.isAuthenticated = false
                state.user = {}
                state.layouts = {}
                state.errors = {}
                JwtService.destroyToken()

                window.location.href = '/login';
                return Promise.reject(refreshError);
              });
          } else {
            return new Promise((resolve, reject) => {
              refreshAndRetryQueue.push({ config: originalConfig, resolve, reject });
            });
          }
        }

        return Promise.reject(error);
      }
    );

    JwtService.saveUser(state.user)
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false
    state.user = {}
    state.layouts = {}
    state.errors = {}
    JwtService.destroyToken()
  },
  [SET_SETTING_IS_SHOW_ACTION_AS_ICON](state, showActionAsIconState) {
    state.user.settingIsShowActionAsIcon = showActionAsIconState
  },
  [SET_LAYOUTS](state, layouts) {
    state.layouts = layouts
    JwtService.saveLayouts(state.layouts)
  },
  [SET_USER_PERMISSIONS](state, permissions) {
    state.user.permissionList = permissions
    JwtService.savePermissions(state.user.permissionList)
  },
  [SET_ELISA_USER](state, elisaUser) {
    state.user.elisaUser = elisaUser
    JwtService.saveElisaUser(state.user.elisaUser)
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
